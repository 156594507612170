import { ORDER_TYPES } from '@nandosaus/constants';
import gql from 'graphql-tag';

import { PRODUCT_FIELDS_FRAGMENT } from '../../helpers/get-product';

const MENU_QUERY = gql`
  query getMenu($restaurantId: String!, $loadDetails: Boolean, $orderType: OrderType) {
    menu(input: { restaurantId: $restaurantId, loadDetails: $loadDetails, orderType: $orderType }) {
      id
      name
      categories {
        name
        handle
        description
        products {
          ...ProductFields
        }
      }
      nutrition
      recommendedProducts
      featuredProducts
      menuPromotion {
        enabled
        name
        description
        plu
      }
      showStockShortageAlert
    }
  }

  ${PRODUCT_FIELDS_FRAGMENT}
`;

const getMenu = async ({ client, restaurantId, loadDetails, orderType }) => {
  try {
    const { data } = await client.query({
      query: MENU_QUERY,
      variables: { restaurantId, loadDetails, orderType: orderType || ORDER_TYPES.PICK_UP },
    });
    return { data: data.menu };
  } catch (error) {
    return { error };
  }
};

export { getMenu };
