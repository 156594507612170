import { template } from 'lodash';
import { types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

const ValidationError = types
  .model('ValidationError', {
    message: '', // needed?
    // code?
    id: types.optional(types.string, uuidv4),
    path: '',
    extensions: types.frozen({}),
    template: '',
  })
  .views(self => ({
    toString() {
      const templateFn = template(self.template);
      const string = templateFn(self.extensions);

      return string;
    },
  }));

export default ValidationError;
