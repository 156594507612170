const keyMirror = require('key-mirror');
const keys = require('lodash/keys');
const invert = require('lodash/invert');
const keyBy = require('lodash/keyBy');
const toUpper = require('lodash/toUpper');
const toLower = require('lodash/toLower');

const { colors } = require('./colors');

const URL = {
  INTERNAL: {
    OUR_STORY: '/our-story',
  },
  EXTERNAL: {
    SPOTIFY: 'https://open.spotify.com/user/nandosuk',
  },
};

const MARKET = {
  AUSTRALIA: 'au',
  NEW_ZEALAND: 'nz',
};

const ABBREVIATED_STATE_NAMES = keyMirror({
  VIC: null,
  NSW: null,
  ACT: null,
  SA: null,
  TAS: null,
  WA: null,
  QLD: null,
  NT: null,
});

const ALERT_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

const BADGE_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  RED: 'red',
};

const CANONICAL_CHOICE_NAMES = keyMirror({
  ADD: 0,
  BASTING: 0,
  CHOOSE_TYPE: 0, // This one is for chips and should be renamed in Redcat.
  DRINK: 0,
  FILLING: 0,
  PROTEIN: 0,
  REMOVE: 0,
  SAUCE: 0,
  SIDE: 0,
  SIDE_1: 0,
  SIDE_2: 0,
  SIDE_3: 0,
  STYLE: 0,
  BYO_COOKED_ITEMS: 0,
  BYO_EXTRA_PROTEIN: 0,
  BYO_CHEESE: 0,
  BYO_SALADS: 0,
  BYO_SAUCE: 0,
});

const { BYO_COOKED_ITEMS, BYO_EXTRA_PROTEIN, BYO_CHEESE, BYO_SALADS, BYO_SAUCE } = CANONICAL_CHOICE_NAMES;
const BYO_EXTRA_INGREDIENTS_CANONICAL_NAMES = [BYO_CHEESE, BYO_SALADS, BYO_SAUCE, BYO_EXTRA_PROTEIN, BYO_COOKED_ITEMS];

// The CANONICAL_CHOICE_MAPPING is used to overcome issues during migrateTo()
// where choices that should be the same are not the same within the menu data.
// For example:
// Products like 1/4 Chicken (ITEM) uses "1/4 Chicken Basting",
// whereas 1/4 Chicken Meal (MEAL) uses "Basting".

// Some of these are entries are empty so that they use their original name as the canonical name.
// This is deliberate because Products like "Two's a Party" contain:
// 2 x 1/4 Chicken, 2 x Tender, 2 x Thigh and would cause both to map to the
// first corresponding BASTING choice if they were all mapped as BASTING.

const CANONICAL_BASTING_MAPPING = {
  Basting: CANONICAL_CHOICE_NAMES.BASTING,
  'Then, pick your heat': CANONICAL_CHOICE_NAMES.BASTING,
};

const CANONICAL_PROTEIN_MAPPING = {
  Protein: CANONICAL_CHOICE_NAMES.PROTEIN,
  'First, choose your protein': CANONICAL_CHOICE_NAMES.PROTEIN,
};

const CANONICAL_MEAL_CHOICE_MAPPING = {
  'Add a drink': CANONICAL_CHOICE_NAMES.DRINK,
  Drink: CANONICAL_CHOICE_NAMES.DRINK,
  'Pick a drink': CANONICAL_CHOICE_NAMES.DRINK,
  'Add a side': CANONICAL_CHOICE_NAMES.SIDE,
  'Pick a side': CANONICAL_CHOICE_NAMES.SIDE,
  Side: CANONICAL_CHOICE_NAMES.SIDE,
};

const CANONICAL_CHOICE_MAPPING = {
  ...CANONICAL_MEAL_CHOICE_MAPPING,
  ...CANONICAL_BASTING_MAPPING,
  ...CANONICAL_PROTEIN_MAPPING,
  "Add Nando's PERi-PERi sauce": CANONICAL_CHOICE_NAMES.SAUCE,
  '1/2 Chicken Basting': CANONICAL_CHOICE_NAMES.BASTING,
  '1/4 Basting 1': '',
  '1/4 Basting 2': '',
  '1/4 Chicken Basting': CANONICAL_CHOICE_NAMES.BASTING,
  'Chicken Basting': '',
  'Choose Type': CANONICAL_CHOICE_NAMES.CHOOSE_TYPE, // @NOTE: Should really be CHIP CANONICAL_CHOICE_NAMES.TYPE.
  'Meatball Basting': CANONICAL_CHOICE_NAMES.BASTING,
  'Paella Basting': CANONICAL_CHOICE_NAMES.BASTING,
  'Ribs Basting': '',
  'Side 1': CANONICAL_CHOICE_NAMES.SIDE_1,
  'Side 2': CANONICAL_CHOICE_NAMES.SIDE_2,
  'Side 3': CANONICAL_CHOICE_NAMES.SIDE_3,
  'Sides 1': CANONICAL_CHOICE_NAMES.SIDE_1,
  'Sides 2': CANONICAL_CHOICE_NAMES.SIDE_2,
  'Tender Basting 1': '',
  'Tender Basting 2': '',
  'Tenderloin Basting': CANONICAL_CHOICE_NAMES.BASTING,
  'Tenders Basting': '',
  'Thigh Basting 1': '',
  'Thigh Basting 2': '',
  'Thigh Bites Basting': CANONICAL_CHOICE_NAMES.BASTING,
  'Thighs Basting': CANONICAL_CHOICE_NAMES.BASTING,
  'Veggie Patty Basting': CANONICAL_CHOICE_NAMES.BASTING,
  'Whole Chicken 1 Basting': '',
  'Whole Chicken 2 Basting': '',
  'Whole Chicken Basting': CANONICAL_CHOICE_NAMES.BASTING,
  'Perfectly paired': '', // @TODO: Mapping? (will currently default to 'Perfectly paired')
  'Protein 1': '',
  'Protein 2': '',
  'Cooked items': CANONICAL_CHOICE_NAMES.BYO_COOKED_ITEMS,
  'Extra Protein': CANONICAL_CHOICE_NAMES.BYO_EXTRA_PROTEIN,
  Cheese: CANONICAL_CHOICE_NAMES.BYO_CHEESE,
  'Salad items': CANONICAL_CHOICE_NAMES.BYO_SALADS,
  Sauces: CANONICAL_CHOICE_NAMES.BYO_SAUCE,
  Extra: CANONICAL_CHOICE_NAMES.ADD,
  Extras: CANONICAL_CHOICE_NAMES.ADD,
  Filling: CANONICAL_CHOICE_NAMES.FILLING,
  Style: CANONICAL_CHOICE_NAMES.STYLE,
  Subtract: CANONICAL_CHOICE_NAMES.REMOVE,
  Remove: CANONICAL_CHOICE_NAMES.REMOVE,
  'Remove items': CANONICAL_CHOICE_NAMES.REMOVE,
};

const CANONICAL_SIDES = [
  CANONICAL_CHOICE_NAMES.SIDE,
  CANONICAL_CHOICE_NAMES.SIDE_1,
  CANONICAL_CHOICE_NAMES.SIDE_2,
  CANONICAL_CHOICE_NAMES.SIDE_3,
];

const BYO_STEP_TITLE_MAPPING = {
  style: 'Choose your fave bun',
  protein: 'Choose some protein',
  basting: 'Choose your fave basting',
  extras: "Now let's add your fav extras",
};

/**
 * Dietary constants used for "suitable for: " / "includes: "
 * E.g. PARTIAL means traces or maybe; often accompanied by dietaryNotes to further qualify.
 */

const DIETARY = {
  NO: 0,
  PARTIAL: 0.5,
  YES: 1,
};

const DIETARY_STATUS = keyMirror({
  NO: 0,
  STRICT: 0,
  YES: 0,
});

const DIETARY_TYPES = {
  VEGETARIAN: 'Vegetarian',
  VEGAN: 'Vegan',
  NO_DAIRY: 'No Dairy',
  NO_GLUTEN: 'No Gluten',
};

const DIETARY_STATUS_LOOKUP = {
  0: 'NO',
  0.5: 'YES',
  1: 'STRICT',
};

const DIETARY_STATUS_LOOKUP_INVERSE = invert(DIETARY_STATUS_LOOKUP);

const DIETARY_PREFERENCES_OPTIONS = {
  VEGETARIAN: {
    code: 'VG',
    iconName: 'vegetarian',
    label: DIETARY_TYPES.VEGETARIAN,
    description: 'May contain traces of meat from possible cross-contact',
  },
  VEGAN: {
    code: 'V',
    iconName: 'vegan',
    label: DIETARY_TYPES.VEGAN,
    description: 'May contain traces of animal products from possible cross-contact',
  },
  NO_DAIRY: {
    code: 'ND',
    iconName: 'noDairy',
    label: 'Not containing dairy',
    description: 'May contain traces of dairy from possible cross-contact',
  },
  NO_GLUTEN: {
    code: 'NG',
    iconName: 'noGluten',
    label: 'Not containing gluten',
    description: 'May contain traces of gluten from possible cross-contact',
  },
};

const BASTING_TYPES = {
  EXTRA_HOT: 'extraHot',
  HOT: 'hot',
  MILD: 'mild',
  LEMON_AND_HERB: 'lemonAndHerb',
  BBQ: 'bbq',
  PLAIN: 'plain',
};

/**
 * @deprecated
 * Because the plain basting has a different shape, we have a dedicated utility to generate BASTINGS
 * and BASTING_TYPES_BY_NAME depending on the relevent market value.
 * See getBastingsForMarket utility in the constants package.
 */
const BASTINGS = {
  [BASTING_TYPES.EXTRA_HOT]: {
    name: 'Extra Hot',
    description: 'Like tackling a ferociously fiery dragon',
  },
  [BASTING_TYPES.HOT]: {
    name: 'Hot',
    description: 'Highly combustible - proceed with caution',
  },
  [BASTING_TYPES.MILD]: {
    name: 'Mild',
    description: 'Hits the spot without scalding your tonsils',
  },
  [BASTING_TYPES.LEMON_AND_HERB]: {
    name: 'Lemon & Herb',
    description: 'A mere hint of heat but a tidal wave of flavour',
  },
  [BASTING_TYPES.BBQ]: {
    name: 'BBQ',
    description: 'An enticing smoky sweet flavour with a touch of heat',
  },
  [BASTING_TYPES.PLAIN]: {
    name: { [MARKET.AUSTRALIA]: 'Plain-ish', [MARKET.NEW_ZEALAND]: 'Plain... ish' },
    description: 'Marinated in PERi-PERi, but grilled with no added spice. As mild as we go',
  },
};

/**
 * @deprecated
 * Because the plain basting has a different shape, we have a dedicated utility to generate BASTINGS
 * and BASTING_TYPES_BY_NAME depending on the relevent market value.
 * See getBastingsForMarket utility in the constants package.
 */
const BASTING_TYPES_BY_NAME = {
  [BASTINGS[BASTING_TYPES.EXTRA_HOT].name]: BASTING_TYPES.EXTRA_HOT,
  [BASTINGS[BASTING_TYPES.HOT].name]: BASTING_TYPES.HOT,
  [BASTINGS[BASTING_TYPES.MILD].name]: BASTING_TYPES.MILD,
  [BASTINGS[BASTING_TYPES.LEMON_AND_HERB].name]: BASTING_TYPES.LEMON_AND_HERB,
  [BASTINGS[BASTING_TYPES.BBQ].name]: BASTING_TYPES.BBQ,
  [BASTINGS[BASTING_TYPES.PLAIN].name]: BASTING_TYPES.PLAIN,
};

const OFFER_TYPES = {
  RESTAURANT: 1,
  ONLINE: 2,
  BOTH: 3,
};

const OFFER_TYPES_LABELS = {
  [OFFER_TYPES.RESTAURANT]: 'Redeem in Restaurant',
  [OFFER_TYPES.ONLINE]: 'Redeem Online',
  [OFFER_TYPES.BOTH]: 'Redeem Offer',
};

const ERROR_CODES = keyMirror({
  PLACEHOLDER: 0,
});

const PRODUCT_MODIFIERS = keyMirror({
  ITEM: 0,
  MEAL: 0,
});

const ORDER_TIMES = keyMirror({
  ASAP: 0,
});

const ORDER_TYPES = keyMirror({
  DELIVERY: 0,
  DINE_IN: 0,
  // DRIVE_THROUGH: 0,
  PICK_UP: 0,
  // TAKE_AWAY: 0,
  WEB_ORDERING: 0,
});

const CONTEXT_TYPES = keyMirror({
  GENERAL: 0,
  PRESET: 0,
  GROUP: 0,
});

const ORDER_TYPES_LABELS = {
  [ORDER_TYPES.DELIVERY]: 'Delivery',
  [ORDER_TYPES.DINE_IN]: 'Dine in',
  [ORDER_TYPES.PICK_UP]: 'Pick up',
  [ORDER_TYPES.WEB_ORDERING]: 'Web ordering',
};

const DISCOUNT_TYPES = keyMirror({
  DOLLARS_OFF_ORDER: 0,
  DOLLARS_OFF_PRODUCT: 0,
  PERCENTAGE_OFF_ENFORCED_PRODUCT: 0,
  PERCENTAGE_OFF_ORDER: 0,
  PERCENTAGE_OFF_ORDER_ITEM: 0,
  PERCENTAGE_OFF_PRODUCT: 0,
  PRICE_OVERRIDE: 0,
});

const SCHEDULE_TYPES = keyMirror({
  ASAP: 0,
  SCHEDULED: 0,
});

const DEFAULT_RESTAURANT_CLOSING_OFFSET_IN_MINUTES = 15;
const RESTAURANT_CLOSING_OFFSET_IN_MINUTES_BY_ORDER_TYPE = {
  [ORDER_TYPES.DINE_IN]: 15,
  [ORDER_TYPES.PICK_UP]: 15,
  [ORDER_TYPES.DELIVERY]: 30,
};

const PERKS_SHOP_STORE_ID = 999;
const PERKS_SHOP_REQUEST_URI = `stores/999/keypad/WEB%20MENU`;

const PAYMENT_TYPES = keyMirror({
  APPLE_PAY: 0,
  GOOGLE_PAY: 0,
  CREDIT_CARD: 0,
  GIFT_CARD: 0,
  LOYALTY: 0,
  PAYPAL: 0,
  GROUP_HOST: 0,
});

// Used for showing indicative price of order (including surcharges) before payment method is known i.e when showing recommendation engine results
const DEFAULT_PAYMENT_TYPE = PAYMENT_TYPES.CREDIT_CARD;

const REFUND_TYPES = keyMirror({
  VOUCHER: 0, // refund via gift card
  REFUND: 0, // refund via payment method of original order
  SUPPORT_TICKET: 0,
});

const REFUND_REFUSAL_REASONS = keyMirror({
  INVALID: 0,
  REFUND_AMOUNT_TOO_HIGH: 0,
  REFUND_NOT_AVAILABLE_AT_RESTAURANT: 0,
  REFUND_NOT_AVAILABLE_FOR_GUESTS: 0,
  TOO_MANY_REFUNDS_REQUESTED: 0,
  ALREADY_REFUNDED: 0,
  OUTSIDE_REFUND_TIME_RANGE: 0,
});

const REFUND_LOG_ENTRY_PK = 'REFUND_LOG_ENTRY';

const RECEIPT_TYPES = keyMirror({
  EMAIL: 0,
  SMS: 0,
  BOTH: 0,
  NONE: 0,
});

const DATE_TIME_FORMATS = {
  DATE_MONTH_DAY: 'MMMM D',
  DATE_DAY_MONTH_FULL: 'D MMMM',
  DATE_DAY_MONTH: 'D MMM',
  DATE_STRING: 'Do MMM YYYY',
  DATE_SLASH: 'DD/MM/YYYY',
  DATE_API_FORMAT: 'YYYY-MM-DD',
  TIME_DEFAULT: 'h:mm a',
  TIME_24_HOUR: 'HH:mm',
  TIME_12_HOUR: 'ha',
  TIME_FULL_DAY: 'dddd',
  TIME_MINUTES: 'm',
  TIME_HOURS: 'h',
};

const WARNING_AGE_YEARS = 18;

const DEFAULT_PICKUP_TIMES = {
  OPENING_TIME: '11:00',
  CLOSING_TIME: '22:00',
};

const PICKUP_TIME_INTERVAL = 15;

const DEFAULT_COORDINATES = {
  LATITUDE: -37.8136276,
  LONGITUDE: 144.9630576,
};

const DELIVERABILITY_STATUS = keyMirror({
  COURIERS_UNAVAILABLE: 0,
  DELIVERABLE: 0,
  OUTSIDE_DELIVERY_AREA: 0,
  OUTSIDE_DELIVERY_HOURS: 0,
  UNKNOWN: 0,
  ADDRESS_MISSING_POSTCODE: 0,
});

const DELIVERY_ERROR_MESSAGES = {
  CANNOT_FETCH_DELIVERY_ESTIMATE: "We've been unable to get a delivery estimate for your address",
  COURIERS_UNAVAILABLE: 'Unfortunately there are no drivers available in your area at the moment.',
  OUTSIDE_DELIVERY_AREA:
    'We’re sorry, but your nearest Nando’s can’t do deliveries at the moment. Want to see if you can dine in or takeaway instead?',
  OUTSIDE_DELIVERY_HOURS: "Unfortunately you're currently outside of delivery hours.",
  ADDRESS_MISSING_POSTCODE: 'The delivery address selected is missing an associated postcode.',
};

const COGNITO_AUTH_FLOWS = {
  FORGOT_PASSWORD: 'forgot-password',
};

// based on this guide https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
const INPUT_AUTOCOMPLETE = {
  EMAIL: 'email',
  FAMILY_NAME: 'family-name',
  GIVEN_NAME: 'given-name',
  NEW_PASSWORD: 'new-password',
  ONE_TIME_CODE: 'one-time-code',
  PASSWORD: 'current-password',
  PHONE: 'tel',
  POSTCODE: 'postal-code',
  STREET: 'address-line1',
  SUBURB: 'address-level2',
  USERNAME: 'username',
};

const INPUT_TYPE = {
  CREDIT_CARD: 'creditCard',
  DATE: 'date',
  EMAIL: 'email',
  EXPIRY_DATE: 'expiryDate',
  PHONE: 'phone',
  NUMBER: 'number', // numbers only
  NUMERIC: 'numeric', // numbers and '.'
  PASSWORD: 'password',
  SELECT: 'select',
  TEXT: 'text',
  TIME: 'time',
};

const GENDER_TYPES = [
  { text: 'Male', value: 'male' },
  { text: 'Female', value: 'female' },
  { text: 'Indeterminate', value: 'unspecified' },
];

const NUTRITION_UNITS = {
  'Energy (Kcal)': {
    label: 'Energy',
    unit: 'Kcal',
    indentValue: false,
  },
  'Energy (kJ)': {
    label: 'Energy',
    unit: 'kJ',
    indentValue: false,
  },
  'Total Fat (g)': {
    label: 'Total Fat',
    unit: 'g',
    indentValue: false,
  },
  'Saturated Fat (g)': {
    label: 'Saturated Fat',
    unit: 'g',
    indentValue: true,
  },
  'Total Carb (g)': {
    label: 'Total Carb',
    unit: 'g',
    indentValue: false,
  },
  'Sugar (g)': {
    label: 'Sugar',
    unit: 'g',
    indentValue: true,
  },
  'Protein (g)': {
    label: 'Protein',
    unit: 'g',
    indentValue: false,
  },
  'Sodium (mg)': {
    label: 'Sodium',
    unit: 'mg',
    indentValue: false,
  },
  'Weight (g)': {
    label: 'Weight',
    unit: 'g',
    indentValue: false,
  },
};

const NUTRITION_UNITS_100G = {
  'Energy (Kcal) (100g)': {
    label: 'Energy',
    unit: 'Kcal',
    indentValue: false,
  },
  'Energy (kJ) (100g)': {
    label: 'Energy',
    unit: 'kJ',
    indentValue: false,
  },
  'Total Fat (g) (100g)': {
    label: 'Total Fat',
    unit: 'g',
    indentValue: false,
  },
  'Saturated Fat (g) (100g)': {
    label: 'Saturated Fat',
    unit: 'g',
    indentValue: true,
  },
  'Total Carb (g) (100g)': {
    label: 'Total Carb',
    unit: 'g',
    indentValue: false,
  },
  'Sugar (g) (100g)': {
    label: 'Sugar',
    unit: 'g',
    indentValue: true,
  },
  'Protein (g) (100g)': {
    label: 'Protein',
    unit: 'g',
    indentValue: false,
  },
  'Sodium (mg) (100g)': {
    label: 'Sodium',
    unit: 'mg',
    indentValue: false,
  },
};

const NUTRITION_ATTRIBUTES = keys(NUTRITION_UNITS);
const NUTRITION_ATTRIBUTES_100G = keys(NUTRITION_UNITS_100G);

const NUTRITION_DISCLAIMER_MESSAGES = {
  SIDE_CHOICE: 'Info does not include Side choices. Please refer to info for each Side item individually.',
  CUSTOMISATION: 'Info does not include any customisations made to menu items.',
};

const CONTAINS_ATTRIBUTES = [
  'Fish',
  'Gluten',
  'Lupin',
  'Milk',
  'Sesame',
  'Soy',
  'Shellfish',
  'Sulphites',
  'Egg',
  'Peanuts',
  'Tree Nuts',
];

const SUITABILITY_ATTRIBUTES = ['Lactose Intolerants', 'Coeliacs', 'Nut Allergies', 'Vegan', 'Vegetarian'];

// The nutrition data file uses 'field5' and 'field6' to store the protein and style options respectively.
// It is due to how the spreadsheet is supplied before being uploaded to Dynamics.
const NUTRITION_PROTEIN_KEY = 'field5';
const NUTRITION_STYLE_KEY = 'field6';

const MEMBER_TYPES = {
  GUE: 'GUE',
  PERI_PERKS: 'PERi-Perks',
};

const PAYMENT_METHODS = {
  POINTS: 'points',
  APPLE_PAY: 'applepay',
  GOOGLE_PAY: 'googlepay',
  GIFT_CARD: 'giftCard',
  AMEX: 'amex',
  MC: 'mc',
  VISA: 'visa',
  PAYPAL: 'paypal',
};

const PAYMENT_METHOD_LABELS = {
  PAY_PAL: 'PayPal',
  APPLE_PAY: 'Apple Pay',
  GOOGLE_PAY: 'Google Pay',
  MASTER_CARD: 'Ending 5454',
};

const DEFAULT_NANDOS_PHONE = '1300 626 367';

const MAX_LOCATION_DISTANCE = 200000;

/**
 * Reg Exp to extract plu from Nando's product ids
 * (which currently take the format `${restaurantId}-${plu} or `DELIVERY-${restaurantId}-${plu}`
 */
const PRODUCT_ID_TO_PLU_REG_EX = /-(\d+)$/;

const ORDER_STATUS = Object.freeze({
  NEW: 'NEW',
  PROCESSING: 'PROCESSING',
  NETWORK_ERROR: 'NETWORK_ERROR',
  PAYMENT_ACTION_REQUIRED: 'PAYMENT_ACTION_REQUIRED',
  PAYMENT_CHALLENGED: 'PAYMENT_CHALLENGED',
  SUBMITTED: 'SUBMITTED',
  PAYMENT_ERROR: 'PAYMENT_ERROR',
  FATAL_ERROR: 'FATAL_ERROR',
  RECAPTCHA_V3_FAILED: 'RECAPTCHA_V3_FAILED',
});

const ORDER_ACTION_TYPES = Object.freeze({
  RECAPTCHA_CHALLENGE: 'recaptchaChallenge',
  THREE_DS2_CHALLENGE: 'threeDS2Challenge',
});

const ENQUIRY_TOPICS = {
  GROCERY_PRODUCTS: 'Nando’s grocery products & sauces',
  PR_AND_MEDIA: 'PR & media enquiry',
  WORK_OPPORTUNITIES: 'Work opportunities',
  RESTAURANT_FEEDBACK: 'Restaurant feedback',
  CATERING: 'Nando’s catering',
  OTHER: 'Something else',
};

const HOTJAR_SURVEYS = {
  DELIVERY_EXPERIENCE: 'delivery-survey',
  DINE_IN_EXPERIENCE: 'dine-in-survey',
  PICK_UP_EXPERIENCE: 'pick-up-survey',
  QR_DINE_IN_EXPERIENCE: 'qr-dine-in-survey',
};

const WEBSOCKET_DATA_TYPE = keyMirror({ ORDER_STATUS_UPDATE: 0 });

const PREVIOUS_CART_KEY = 'PreviousCart';
const ORDERING_CONTEXT_KEY = 'OrderingContext';
const GROUP_ORDER_KEY = 'GroupOrder';
const GROUP_SESSION_ID_KEY = 'GroupSessionId';
const SAVED_DIETARY_PREFERENCES_KEY = 'SavedDietaryPreferences';
const MENU_WELCOME_DISMISSED_KEY = 'nd_menu_welcome_dismissed';
const MENU_PROMOTION_DISMISSED_KEY = 'nd_menu_promotion_modal_dismissed';

const PRODUCT_EXISTS_IN_CART_KEY = 'PRODUCT_EXISTS_IN_CART_KEY';

const DEFAULT_PRODUCT_DISCLAIMER = 'The average adult daily energy intake is 8,700kJ.';
const DEFAULT_PRODUCT_PROTEIN_CHOICE = 'Chicken';
const DEFAULT_PRODUCT_STYLE_CHOICE = 'Soft white bun';
const SIDE_NOT_INCLUDED_INITIALLY_DISCLAIMER = `Price and initial kJ does not include a side and is based on ${toLower(
  DEFAULT_PRODUCT_PROTEIN_CHOICE
)} & ${toLower(
  DEFAULT_PRODUCT_STYLE_CHOICE
)}. kJ value will update when you select your protein and style.\n${DEFAULT_PRODUCT_DISCLAIMER}`;
const SIDE_NOT_INCLUDED_INITIALLY_DISCLAIMER_NANDOCAS = `Price and initial kJ does not include a side.\n${DEFAULT_PRODUCT_DISCLAIMER}`;
const SIDE_NOT_INCLUDED_INITIALLY_DISCLAIMER_NANDOCAS_PLUS = ['1001314', '1001316'];
const KIDS_PRODUCT_DISCLAIMER = `kJ is based on the item with chips. kJ count does not include drink. ${DEFAULT_PRODUCT_DISCLAIMER}`;
const SIDE_OPTIONS_REQUIRED_PRODUCT_DISCLAIMER = `kJ do not include side choices. ${DEFAULT_PRODUCT_DISCLAIMER}`;
const SIDE_AND_DRINK_OPTIONS_REQUIRED_PRODUCT_DISCLAIMER = `kJ do not include side and drink choices. ${DEFAULT_PRODUCT_DISCLAIMER}`;
const SIDE_AND_SNACK_PRODUCT_DISCLAIMER = `kJ do not include snack and side choices. ${DEFAULT_PRODUCT_DISCLAIMER}`;

const APP_QR_CODE_URL = 'https://qrco.de/bbRqPP';

const ORDER_UPDATE_STATUS = keyMirror({
  // Order created via our platform
  CREATED: 0,
  // DELIVERY STATUS
  UNASSIGNED: 0, // Delivery Created
  ACCEPTED: 0, // Dasher Confirmed
  ENROUTE_TO_PICKUP: 0, // Dasher enroute to pickup
  ARRIVED_AT_STORE: 0, // Dasher arrived at store
  PICKED_UP: 0, // Dasher Picked Up
  ENROUTE_TO_DROPOFF: 0, // Dasher enroute to dropoff
  ARRIVED_AT_CONSUMER: 0, // Dasher Confirmed Consumer Arrival
  DROPPED_OFF: 0, // Dasher Dropped Off
  // POS STATUS
  RECEIVED: 0, // Order received by the POS
  PROCESSED: 0, // Order accepted by the POS
  // Kitchen Management Software
  SERVED_BY_KITCHEN: 0,
  // Just in case we are link huh??
  UNKNOWN: 0, // We don't know what the status is
});

const MENU_CATEGORIES = {
  ALCOHOL: 'alcohol',
  SPIN_TO_WIN: 'spin2Win',
  KIDS: 'kids',
  BYO: 'byo',
  BWP: 'bwp',
};

const ALCOHOL_DISCLAIMER =
  'Nando’s Australia supports the responsible service of alcohol. It is against the law to sell or supply liquor to a person under the age of 18 years.';

// retaining the capability to set different disclaimers for different states, for now it is duplicated
const ALCOHOL_DISCLAIMERS_BY_STATE = {
  NSW: ALCOHOL_DISCLAIMER,
  QLD: ALCOHOL_DISCLAIMER,
  SA: ALCOHOL_DISCLAIMER,
  VIC: ALCOHOL_DISCLAIMER,
  WA:
    'Nando’s Australia supports the responsible service of alcohol. It is against the law to sell or supply liquor to a person under the age of 18 years on licensed or regulated premises, or for a person under the age of 18 years to purchase, or attempt to purchase, liquor on licensed or regulated premises. Nando’s Australia does not sell alcohol to persons under the age of 18 and does not sell alcohol to persons without the purchase of food in WA. By using the Online Ordering Platform, and submitting your order through the Online Ordering Platform, you confirm you are making an offer to purchase, that you are over the age of 18, that you are the person receiving the alcohol and that you are aware of, and will comply with, all relevant laws regarding the purchase and consumption of alcohol. It is a condition of purchase of alcohol that you provide valid identification to Nando’s Australia, and that the offer otherwise complies with applicable liquor laws, otherwise your purchase offer will be refused and payment for your order will be refunded within 7 business days. The average adult daily energy intake is 8,700kJ',
};

const ALCOHOL_WITH_FOOD_ERROR_MESSAGE =
  'Alcohol legally requires a food purchase in WA. Please select a menu item to continue.';

const ORDER_ISSUE_TYPES = {
  NEVER_ARRIVED: 'My order never arrived',
  WRONG_OR_MISSING_ITEM: 'Wrong or missing items',
  QUALITY: 'Food damage or quality issue',
  DIETARY_REQUIREMENTS_NOT_MET: 'Dietary requirements were not met',
  OTHER: 'Other',
};

const ORDER_ITEM_ISSUES = {
  QUALITY: {
    TEMPERATURE: 'Temperature',
    PRESENTATION: 'Presentation',
    PORTION_SIZE: 'Portion Size',
  },
  DELIVERY: {
    WRONG_ITEM: 'Item was incorrect',
    MISSING_ITEM: 'Item was missing',
  },
};

const { NEVER_ARRIVED, WRONG_OR_MISSING_ITEM, QUALITY, DIETARY_REQUIREMENTS_NOT_MET, OTHER } = ORDER_ISSUE_TYPES;

const ORDER_HELP_FLOW_STEPS = keyMirror({
  SELECT_ISSUE: 0,
  SELECT_ORDER: 0,
  SELECT_ORDER_ITEM: 0,
  SELECT_ORDER_ITEM_ISSUE: 0,
  ADD_NOTES: 0,
  SELECT_REFUND_TYPE: 0,
  CONFIRMATION: 0,
});

const {
  SELECT_ISSUE,
  SELECT_ORDER,
  SELECT_ORDER_ITEM,
  SELECT_ORDER_ITEM_ISSUE,
  ADD_NOTES,
  SELECT_REFUND_TYPE,
  CONFIRMATION,
} = ORDER_HELP_FLOW_STEPS;
const ORDER_HELP_FLOW_STEPS_BY_ISSUE_TYPE = {
  [NEVER_ARRIVED]: [SELECT_ISSUE, SELECT_ORDER, ADD_NOTES, SELECT_REFUND_TYPE, CONFIRMATION],
  [WRONG_OR_MISSING_ITEM]: [
    SELECT_ISSUE,
    SELECT_ORDER,
    SELECT_ORDER_ITEM,
    SELECT_ORDER_ITEM_ISSUE,
    ADD_NOTES,
    SELECT_REFUND_TYPE,
    CONFIRMATION,
  ],
  [QUALITY]: [
    SELECT_ISSUE,
    SELECT_ORDER,
    SELECT_ORDER_ITEM,
    SELECT_ORDER_ITEM_ISSUE,
    ADD_NOTES,
    SELECT_REFUND_TYPE,
    CONFIRMATION,
  ],
  [DIETARY_REQUIREMENTS_NOT_MET]: [
    SELECT_ISSUE,
    SELECT_ORDER,
    SELECT_ORDER_ITEM,
    ADD_NOTES,
    SELECT_REFUND_TYPE,
    CONFIRMATION,
  ],
  [OTHER]: [SELECT_ISSUE, SELECT_ORDER, ADD_NOTES, SELECT_REFUND_TYPE, CONFIRMATION],
};
const SUPPORT_TICKET_FLOW = [SELECT_ISSUE, SELECT_ORDER, ADD_NOTES, CONFIRMATION];

const ZENDESK_TICKET_STATUS = {
  NEW: 'new',
  OPEN: 'open',
  PENDING: 'pending',
  HOLD: 'hold',
  SOLVED: 'solved',
  CLOSED: 'closed',
};

const COMPETITION_PRIZE_TYPES = {
  GRAND_PRIZE: 'grandPrize',
  OFFER: 'offer',
  GIFT_CARD: 'giftCard',
};

const COMPETITION_PRIZE_HANDLES = {
  DRINK: 'drink',
  SIDE: 'side',
  GRAND_PRIZE: 'grandPrize',
  GIFT_CARD: 'giftCard',
  CHIP_DIP_VARIETIES: 'chipDipVarieties',
  CHEESY_GARLIC_MINI_PITA: 'cheesyGarlicMiniPita',
  CHOCOLATE_MOUSSE: 'chocolateMousse',
  LARGE_SIDE: 'largeSide',
  NAUGHTY_NATA: 'naughtyNata',
  QUARTER_CHICKEN_MEAL: 'quarterChickenMeal',
};

const COMPETITION_ERRORS = {
  ALL_PRIZES_HAVE_BEEN_WON: 'All prizes have been won',
  NO_PRIZE_WON: 'No prize won',
  NO_PRIZES_AVAILABLE: 'No prizes available',
  ERROR_CLAIMING_PRIZE: 'An error occurred when trying to claim your prize. Please try again.',
  NO_ENTRIES_AVAILABLE: 'No entries for user available',
};

const CONNECTION_ERRORS = {
  FAILED_CODE_504: 'Request failed with status code 504',
  NETWORK_ERROR: 'Network Error',
};

const SPIN_TO_WIN_WHEEL_SEGMENTS = {
  [MARKET.AUSTRALIA]: [
    COMPETITION_PRIZE_HANDLES.CHEESY_GARLIC_MINI_PITA,
    COMPETITION_PRIZE_HANDLES.CHIP_DIP_VARIETIES,
    COMPETITION_PRIZE_HANDLES.DRINK,
    COMPETITION_ERRORS.NO_PRIZE_WON,
    COMPETITION_PRIZE_HANDLES.LARGE_SIDE,
    COMPETITION_PRIZE_HANDLES.SIDE,
    COMPETITION_PRIZE_HANDLES.NAUGHTY_NATA,
    COMPETITION_PRIZE_TYPES.GIFT_CARD,
    COMPETITION_PRIZE_TYPES.GRAND_PRIZE,
  ],
  [MARKET.NEW_ZEALAND]: [
    COMPETITION_PRIZE_HANDLES.CHEESY_GARLIC_MINI_PITA,
    COMPETITION_PRIZE_HANDLES.CHIP_DIP_VARIETIES,
    COMPETITION_PRIZE_HANDLES.CHOCOLATE_MOUSSE,
    COMPETITION_ERRORS.NO_PRIZE_WON,
    COMPETITION_PRIZE_HANDLES.LARGE_SIDE,
    COMPETITION_PRIZE_HANDLES.SIDE,
    COMPETITION_PRIZE_HANDLES.NAUGHTY_NATA,
    COMPETITION_PRIZE_TYPES.GIFT_CARD,
    COMPETITION_PRIZE_TYPES.GRAND_PRIZE,
  ],
};

const ESTIMATED_DELIVERY_TIME = {
  PICK_UP: 'Thanks for your order, it’ll be ready soon.',
};

const BYO_PRODUCT_TYPES = {
  BURGER: 'burger',
  PITA: 'pita',
  WRAP: 'wrap',
};

const BUILD_YOUR_OWN_PRODUCTS = [
  {
    plu: 1000866,
    type: BYO_PRODUCT_TYPES.BURGER,
  },
  {
    plu: 1000867,
    type: BYO_PRODUCT_TYPES.PITA,
  },
  {
    plu: 1000868,
    type: BYO_PRODUCT_TYPES.WRAP,
  },
];

const PRODUCT_BUTTON_LABELS = {
  ADD_ITEM: 'Add Item',
  ADD_MEAL: 'Add Meal',
  BACK: 'Back',
  CONTINUE: 'Continue',
  EDIT_SIDES: 'Edit Sides',
  LOADING: 'Loading',
  MAKE_IT_MEAL: 'Make It A Meal',
  UPDATE_ORDER: 'Update Order',
};

const FRAME_COLOR_VARIANTS = {
  pink: { container: colors.pink200, pepper: colors.pink200, bottomBar: colors.pink500, fireSymbol: colors.pink500 },
  salmon: { container: colors.pink100, pepper: colors.pink200, bottomBar: colors.pink500, fireSymbol: colors.pink500 },
  yellow: {
    container: colors.yellow100,
    pepper: colors.yellow100,
    bottomBar: colors.yellow500,
    fireSymbol: colors.yellow500,
  },
  green: {
    container: colors.green100,
    pepper: colors.green100,
    bottomBar: colors.green500,
    fireSymbol: colors.green500,
  },
  orange: {
    container: colors.orange200,
    pepper: colors.orange200,
    bottomBar: colors.orange500,
    fireSymbol: colors.orange500,
  },
  teal: {
    container: colors.neonTeal200,
    pepper: colors.neonTeal200,
    bottomBar: colors.neonTeal500,
    fireSymbol: colors.neonTeal500,
  },
};

const FRAME_THEME_COLOR = keyBy(keys(FRAME_COLOR_VARIANTS), toUpper);

const CMS_BLOCK_PATTERN_STYLE = {
  FLAME: 'flame',
  PEPPER: 'pepper',
  TRIANGLE: 'triangle',
};

const DECAL_TYPE = {
  FLAME: 'flame',
  PEPPER: 'pepper',
  HEART: 'heart',
};

const DECAL_TYPES = [DECAL_TYPE.FLAME, DECAL_TYPE.PEPPER, DECAL_TYPE.HEART];

const CMS_BLOCK_THEME_COLOR = {
  GREEN: 'green',
  ORANGE: 'orange',
  PINK: 'pink',
  BLUE: 'blue',
};

const SURCHARGE_TYPE = keyMirror({
  HOLIDAY_SURCHARGE: 0,
  DELIVERY_CLIENT_MANAGED: 0,
  DELIVERY_DOORDASH: 0,
  DELIVERY_UBER: 0,
});

const DELIVERY_SURCHARGE_TYPES = [
  SURCHARGE_TYPE.DELIVERY_CLIENT_MANAGED,
  SURCHARGE_TYPE.DELIVERY_DOORDASH,
  SURCHARGE_TYPE.DELIVERY_UBER,
];

const DELIVERY_PROVIDER = keyMirror({
  CLIENT_MANAGED: 0,
  DOORDASH: 0,
  UBER: 0,
});

// Event names taken from the Segment ecommerce spec
// @see: https://segment.com/docs/connections/spec/ecommerce/v2/

const ANALYTICS_EVENTS = Object.freeze({
  USER_MULTI_FACTOR_AUTH_RESENT: 'User Multi-Factor Auth Resent',
  USER_MULTI_FACTOR_AUTH_HELP_CLICKED: 'User Multi-Factor Auth Help Clicked',
  MENU_CATEGORY_CLICKED: 'Menu Category Clicked',
  ACCOUNT_NAV_CLICKED: 'Account Nav Clicked',
  USER_PROFILE_UPDATED: 'User Profile Updated',
  USER_PROFILE_UPDATE_SUBMITTED: 'User Profile Update Submitted',
  APPLICATION_INSTALL_CLICKED: 'Application Install Clicked',
  PAGE_ERROR: 'Page Error',
  FOOTER_NAV_ITEM_CLICKED: 'Footer Nav Item Clicked',
  HEADER_NAV_ITEM_CLICKED: 'Header Nav Item Clicked',
  ACCORDION_BLOCK_CLICKED: 'Accordion Block Clicked',
  MENU_CAROUSEL_BLOCK_CLICKED: 'Menu Carousel Block Clicked',
  PAGE_HEADER_BLOCK_CLICKED: 'Page Header Block Clicked',
  ADD_PERI_PERKS_CARD_CLICKED: 'Add Peri-Perks Card Clicked',
  ORDER_UPDATE_CLICKED: 'Order Update Clicked',
  PRODUCT_UPDATE_CLICKED: 'Product Update Clicked',
  ALLERGEN_DOCUMENT_VIEWED: 'Allergen Document Viewed',
  PRODUCT_IMAGE_LONG_PRESSED: 'Product Image Long Pressed',
  PRODUCT_IMAGE_PINCHED_TO_ZOOM: 'Product Image Pinched to Zoom',
  RECOMMENDED_PRODUCTS_SCROLLED: 'Recommended Products Scrolled',
  DIETARY_PREFERENCES_CLEARED: 'Dietary Preferences Cleared',
  DIETARY_PREFERENCES_UPDATED: 'Dietary Preferences Updated',
  DIETARY_PREFERENCES_OPENED: 'Dietary Preferences Opened',
  PRODUCT_NUTRITION_VIEWED: 'Product Nutrition Viewed',
  PRODUCT_CONFIRM_SIDES_CLICKED: 'Product Confirm Sides Clicked',
  PRODUCT_MEAL_CTA_CLICKED: 'Product Meal CTA Clicked',
  MENU_CATEGORY_NAVIGATION_CLICKED: 'Menu Category Navigation Clicked',
  ORDER_SETTINGS_DISMISSED: 'Order Settings Dismissed',
  ORDER_SETTINGS_UPDATED: 'Order Settings Updated',
  ORDER_SETTINGS_OPENED: 'Order Settings Opened',
  CONTENT_TILE_PRESSED: 'Content Tile Pressed',
  COMPETITION_ENTERED: 'Competition Entered',
  RESTAURANT_DIRECTIONS_CLICKED: 'Restaurant Directions Clicked',
  PUSH_NOTIFICATIONS_ENABLED: 'Push Notifications Enabled',
  PUSH_NOTIFICATIONS_ALERT_DISMISSED: 'Push Notifications Alert Dismissed',
  PUSH_NOTIFICATIONS_MODAL_DISMISSED: 'Push Notifications Modal Dismissed',
  BROWSE_MENU_CLICKED: 'Browse Menu Clicked',
  BOTTOM_NAVIGATION_CLICKED: 'Bottom Navigation Clicked',
  ORDER_HELP_CLICKED: 'Order Help Clicked',
  ORDER_RECEIPT_ADDED: 'Order Receipt Added',
  ORDER_RECEIPT_FAILED: 'Order Receipt Failed',
  ORDER_RECEIPT_SUBMITTED: 'Order Receipt Submitted',
  USER_ALCOHOL_AGE_VERIFIED: 'User Alcohol Age Verified',
  USER_PASSWORD_RESET: 'User Password Reset',
  USER_PASSWORD_RESET_FAILED: 'User Password Reset Failed',
  USER_PASSWORD_RESET_SUBMITTED: 'User Password Reset Submitted',
  USER_MULTI_FACTOR_HELP_CLICKED: 'User Multi-Factor Help Clicked',
  USER_MULTI_FACTOR_AUTH_CODE_RESENT: 'User Multi-Factor Auth Code Resent',
  USER_MULTI_FACTOR_AUTH_FAILED: 'User Multi-Factor Auth Failed',
  USER_MULTI_FACTOR_AUTHORISED: 'User Multi-Factor Authorised',
  USER_MULTI_FACTOR_AUTH_SUBMITTED: 'User Multi-Factor Auth Submitted',
  USER_RESET_PASSWORD_CLICKED: 'User Reset Password Clicked',
  USER_SIGN_UP_CLICKED: 'User Sign Up Clicked',
  USER_SIGN_IN_FAILED: 'User Sign In Failed',
  USER_SIGN_IN_SUBMITTED: 'User Sign In Submitted',
  LINK_LOYALTY_CARD_CLICKED: 'Link Loyalty Card Clicked',
  USER_SIGN_IN_CLICKED: 'User Sign In Clicked',
  USER_SIGN_UP_FAILED: 'User Sign Up Failed',
  USER_SIGN_UP_SUBMITTED: 'User Sign Up Submitted',
  ORDER_DELIVERY_TRACKING_OPENED: 'Order Delivery Tracking Opened',
  ORDER_CONFIRMATION_CONTENT_CLICKED: 'Order Confirmation Content Clicked',
  ORDER_CONFIRMATION_CONTENT_SCROLLED: 'Order Confirmation Content Scrolled',
  ALLERGEN_PDF_VIEWED: 'Menu Allergen PDF Opened',
  ALCOHOL_RESTRICTION_TRIGGERED: 'WA Alcohol Restriction Triggered',
  PRODUCT_LIST_VIEWED: 'Product List Viewed',
  PRODUCT_CLICKED: 'Product Clicked',
  PRODUCT_VIEWED: 'Product Viewed',
  PRODUCT_ADDED: 'Product Added',
  PRODUCT_REMOVED: 'Product Removed',
  PROMOTION_APPLIED: 'Promotion Applied',
  PROMOTION_VIEWED: 'Promotion Viewed',
  PROMOTION_CLICKED: 'Promotion Clicked',
  PROMOTION_DISMISSED: 'Promotion Dismissed',
  CART_VIEWED: 'Cart Viewed',
  CHECKOUT_STARTED: 'Checkout Started',
  CHECKOUT_STEP_COMPLETED: 'Checkout Step Completed',
  CHECKOUT_STEP_VIEWED: 'Checkout Step Viewed',
  GROUP_ORDER_COMPLETED: 'Group Order Completed',
  GROUP_ORDER_GROUP_CREATED: 'Group Order Group Created',
  GROUP_ORDER_GROUP_JOINED: 'Group Order Group Joined',
  GROUP_ORDER_GROUP_LEFT: 'Group Order Group Left',
  GROUP_ORDER_GROUP_SHARED: 'Group Order Group Shared',
  GROUP_ORDER_INVITEE_SUBMITTED: 'Group Order Invitee Submitted',
  GROUP_ORDER_TYPE_SUBMITTED: 'New Group Order Submitted',
  OFFER_APPLIED: 'Offer Applied',
  OFFER_CLICKED: 'Offer Clicked',
  OFFER_VIEWED: 'Offer Viewed',
  ORDER_COMPLETED: 'Order Completed',
  ORDER_UPDATED: 'Order Updated',
  PAYMENT_INFO_ENTERED: 'Payment Info Entered',
  POPULAR_ITEM_CLICKED: 'Popular Item Clicked',
  PROMO_CLICKED: 'Promo Clicked',
  RECOMMENDED_CART_ABANDONED: 'Recommended Cart Abandoned',
  RECOMMENDED_CART_ACCEPTED: 'Recommended Cart Accepted',
  RECOMMENDED_CART_ADJUSTED_ADDED: 'Recommended Cart Adjusted Added',
  RECOMMENDED_CART_ADJUSTED_REMOVED: 'Recommended Cart Adjusted Removed',
  RECOMMENDED_CART_PURCHASED: 'Recommended Cart Purchased',
  RECOMMENDED_CART_REQUESTED: 'Recommended Cart Requested',
  RECOMMENDED_CART_REQUEST_VIEW: 'Recommended Cart Viewed',
  REORDER_CLICKED: 'Reorder Clicked',
  REORDER_ITEM_EDITED: 'Reorder Item Edited',
  UPSELL_ITEM_ADDED: 'Upsell Item Added',
  UPSELL_ITEM_ADDED_AFTER_REORDER: 'Upsell Item Added After Reorder',
  UPSELL_ITEM_REMOVED: 'Upsell Item Removed',
  UPSELL_ITEM_REMOVED_AFTER_REORDER: 'Upsell Item Removed After Reorder',
  UPSELL_MODAL_CLOSED: 'Upsell Modal Closed',
  UPSELL_MODAL_CLOSED_AFTER_REORDER: 'Upsell Modal Closed After Reorder',
  USER_CLICKED_POPULAR_ITEM_CLICKED: 'Popular Item Clicked',
  USER_SIGNED_IN: 'User Signed In',
  USER_SIGNED_OUT: 'User Signed Out',
  USER_SIGNED_UP: 'User Signed Up',
  USER_VIEWED_MENU_AFTER_REORDER: 'User Viewed Menu After Added Reorder Item',
  WELCOME_PAGE_CHECKOUT_CLICKED: 'Welcome Page Checkout Clicked',
  WELCOME_PAGE_POPULAR_SEEN: 'Welcome Page Popular Seen',
  WELCOME_PAGE_PROMOTIONS_SEEN: 'Welcome Page Promotions Seen',
  WELCOME_PAGE_REORDER_CLICKED: 'Welcome Page Reorder Clicked',
  WELCOME_PAGE_REORDER_SEEN: 'Welcome Page Reorder Seen',
  WELCOME_PAGE_VIEW_ALL_CLICKED: 'Welcome Page View All Clicked',
});

const GA_PROMOTION_SLUGS = Object.freeze({
  RECOMMENDATIONS_ENGINE: 'RECOMMENDATIONS_ENGINE',
});

const DELIVERY_DISCOUNT_SPONSORS = {
  COKE: 'coke',
};

const DELIVERY_DISCOUNT_CATEGORY_DESCRIPTIONS = {
  COKE: 'For 1 week only, get $0 DELIVERY on all 600ml Coke varieties. T&Cs apply.',
};

const NANDOS_LOYALTY_WALLET_TYPES = keyMirror({
  APPLE: null,
  GOOGLE: null,
});

const PLATFORMS = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
};

const APP_STORE_URLS = {
  AU: 'https://itunes.apple.com/au/app/nandos/id491221341',
  NZ: 'https://apps.apple.com/nz/app/nandos-nz/id1533032141',
};

const PLAY_STORE_URLS = {
  AU: 'https://play.google.com/store/apps/details?id=com.redcat.PeriPerks',
  NZ: 'https://play.google.com/store/apps/details?id=nz.co.nandos',
};
const DEFAULT_HEADER_HIGHLIGHT_LINE_SIZE = 23;

const RECOMMENDATION_ENGINE = {
  maxGuests: 15,
  mealTypes: {
    healthy: {
      label: 'LET’S GO LIGHTER',
      value: 'healthy',
      ariaLabel: 'Let’s go lighter',
      description: "Tasty, fresh, and won't weigh you down. Promise.",
      bannerImageWeb: '/static/images/recommendation-engine/diet_select_banner_healthy.png',
    },

    indulgent: {
      label: 'FILL US UP',
      value: 'indulgent',
      ariaLabel: 'Fill us up',
      description: 'We hope you wore your stretchy pants cause all the carbs are coming your way.',
      bannerImageWeb: '/static/images/recommendation-engine/diet_select_banner_indulgent.png',
    },

    balanced: {
      label: 'A BIT OF BOTH',
      value: 'balanced',
      ariaLabel: 'A bit of both',
      description: 'A little light, a little filling. A balanced combo.',
      bannerImageWeb: '/static/images/recommendation-engine/diet_select_banner_balanced.png',
    },
  },
  bannerWeb: '/static/images/recommendation-engine/banner.png',
  initialValues: {
    group: {
      adults: 0,
      kids: 0,
    },
    mealType: '',
    basting: '',
  },
  screens: {
    quiz: 'quiz',
    review: 'review',
  },
};

// This constant contain the length of the Group order ID
// This ID is being used externally in public URLs to be shared among people
// There is a requirement to be as short as possible without having high hash collisions risk
const GROUP_ORDER_ID_LENGTH = 12;

const GROUP_ORDER_STATUS = {
  RECEIVING: 'receiving',
  ORDER_PLACED: 'order-placed',
};

const GROUP_ORDER_TYPES = {
  NONE: 'none-selected',
  EVERYONE_CHOOSES: 'everyone-chooses',
  YOU_CHOOSE: 'you-choose',
};

const HALF_WIDTH_MOBILE_CATEGORY_HANDLES = [
  'startersSnacks', // au
  'starters', // nz
  'sides', // au-nz
  'kidsMeals', // au dine in
  'kidsMealsSnack', // au pick up and delivery
  'nandinos', // nz
  'desserts', // au-nz
];

const INCLUDED_CATEGORIES_HANDLES_ORDER = [
  'specialities', // au
  'chicken', // au-nz
  'mealsToShare', // au
  'platters', // nz
  'bwp', // au-nz
  'salads', // au-nz
  'startersSnacks', // au
  'starters', // nz
  'sides', // au-nz
  'kidsMeals', // au dine in
  'kidsMealsSnack', // au pick up and delivery
  'nandinos', // nz
  'desserts', // au-nz
  'drinks', // au
  'softDrinks', // nz
  'specialities', // nz-staging
];

const MENU_CATEGORY_COLOURS = {
  chicken: colors.neonRed,
  bwp: colors.neonRed,
  desserts: colors.neonPink,
  drinks: colors.blue500,
  kidsMealsSnack: colors.neonLavender,
  mealsToShare: colors.neonRed,
  salads: colors.green500,
  sides: colors.orange500,
  startersSnacks: colors.orange500,
  default: colors.neonRed,
};

const MENU_CATEGORY_FLAME_COLOURS = {
  chicken: colors.orange400,
  bwp: colors.orange400,
  desserts: colors.pink200,
  drinks: colors.blue700,
  kidsMealsSnack: colors.orange400,
  mealsToShare: colors.orange400,
  salads: colors.neonTeal700,
  sides: colors.orange300,
  startersSnacks: colors.orange300,
  default: colors.orange400,
};

const MENU_CATEGORY_IMAGE_SRC = {
  specialities: '/static/images/menu/categories/specialities.png',
  chicken: '/static/images/menu/categories/chicken.png',
  bwp: '/static/images/menu/categories/bwp.png',
  desserts: '/static/images/menu/categories/desserts.png',
  drinks: '/static/images/menu/categories/drinks.png',
  softDrinks: '/static/images/menu/categories/drinks.png',
  kidsMeals: '/static/images/menu/categories/kids-meals-snacks.png',
  kidsMealsSnack: '/static/images/menu/categories/kids-meals-snacks.png',
  nandinos: '/static/images/menu/categories/kids-meals-snacks.png',
  mealsToShare: '/static/images/menu/categories/meals-to-share.png',
  platters: '/static/images/menu/categories/meals-to-share.png',
  salads: '/static/images/menu/categories/salads.png',
  sides: '/static/images/menu/categories/sides.png',
  startersSnacks: '/static/images/menu/categories/starters-snacks.png',
  starters: '/static/images/menu/categories/starters-snacks.png',
  default: '/static/images/menu/categories/chicken.png',
};

const GROUP_CARTS_POLLING_INTERVAL = 1000 * 15; // 15 Seconds
const GROUP_DOES_NOT_EXIST_MESSAGE = 'Group does not exist on the database.';
const GROUP_ORDER_PLACED_MESSAGE = 'This group order has already been submitted.';

const UPSELL_ITEM_MAX_QUANTITY = 9;

const SIGN_UP_VERIFICATION_DISCLAIMER =
  'We’ve sent your account verification link via email. Please verify your account before you sign in.';

module.exports = {
  ABBREVIATED_STATE_NAMES,
  ALCOHOL_DISCLAIMER,
  ALCOHOL_DISCLAIMERS_BY_STATE,
  ALCOHOL_WITH_FOOD_ERROR_MESSAGE,
  ALERT_TYPES,
  APP_QR_CODE_URL,
  APP_STORE_URLS,
  BADGE_TYPES,
  BASTING_TYPES_BY_NAME,
  BUILD_YOUR_OWN_PRODUCTS,
  BYO_PRODUCT_TYPES,
  BYO_STEP_TITLE_MAPPING,
  BYO_EXTRA_INGREDIENTS_CANONICAL_NAMES,
  SIDE_NOT_INCLUDED_INITIALLY_DISCLAIMER,
  SIDE_NOT_INCLUDED_INITIALLY_DISCLAIMER_NANDOCAS,
  SIDE_NOT_INCLUDED_INITIALLY_DISCLAIMER_NANDOCAS_PLUS,
  BASTING_TYPES,
  BASTINGS,
  CANONICAL_BASTING_MAPPING,
  CANONICAL_CHOICE_MAPPING,
  CANONICAL_CHOICE_NAMES,
  CANONICAL_PROTEIN_MAPPING,
  CANONICAL_MEAL_CHOICE_MAPPING,
  CANONICAL_SIDES,
  CMS_BLOCK_PATTERN_STYLE,
  CMS_BLOCK_THEME_COLOR,
  COGNITO_AUTH_FLOWS,
  colors,
  COMPETITION_ERRORS,
  COMPETITION_PRIZE_HANDLES,
  COMPETITION_PRIZE_TYPES,
  CONNECTION_ERRORS,
  CONTAINS_ATTRIBUTES,
  CONTEXT_TYPES,
  DATE_TIME_FORMATS,
  DECAL_TYPE,
  DECAL_TYPES,
  DEFAULT_COORDINATES,
  DEFAULT_HEADER_HIGHLIGHT_LINE_SIZE,
  DEFAULT_NANDOS_PHONE,
  DEFAULT_PAYMENT_TYPE,
  DEFAULT_PICKUP_TIMES,
  DEFAULT_PRODUCT_DISCLAIMER,
  DEFAULT_PRODUCT_PROTEIN_CHOICE,
  DEFAULT_PRODUCT_STYLE_CHOICE,
  DEFAULT_RESTAURANT_CLOSING_OFFSET_IN_MINUTES,
  DELIVERABILITY_STATUS,
  DELIVERY_DISCOUNT_SPONSORS,
  DELIVERY_DISCOUNT_CATEGORY_DESCRIPTIONS,
  DELIVERY_ERROR_MESSAGES,
  DIETARY_PREFERENCES_OPTIONS,
  DELIVERY_PROVIDER,
  DELIVERY_SURCHARGE_TYPES,
  DIETARY_STATUS_LOOKUP_INVERSE,
  DIETARY_STATUS_LOOKUP,
  DIETARY_STATUS,
  DIETARY_TYPES,
  DIETARY,
  DISCOUNT_TYPES,
  ENQUIRY_TOPICS,
  ERROR_CODES,
  ESTIMATED_DELIVERY_TIME,
  FRAME_COLOR_VARIANTS,
  FRAME_THEME_COLOR,
  GA_PROMOTION_SLUGS,
  GENDER_TYPES,
  GROUP_CARTS_POLLING_INTERVAL,
  GROUP_DOES_NOT_EXIST_MESSAGE,
  GROUP_ORDER_ID_LENGTH,
  GROUP_ORDER_KEY,
  GROUP_ORDER_PLACED_MESSAGE,
  GROUP_ORDER_STATUS,
  HOTJAR_SURVEYS,
  INPUT_AUTOCOMPLETE,
  INPUT_TYPE,
  KIDS_PRODUCT_DISCLAIMER,
  MARKET,
  MAX_LOCATION_DISTANCE,
  MEMBER_TYPES,
  MENU_CATEGORIES,
  MENU_PROMOTION_DISMISSED_KEY,
  MENU_WELCOME_DISMISSED_KEY,
  GROUP_SESSION_ID_KEY,
  NANDOS_LOYALTY_WALLET_TYPES,
  NUTRITION_ATTRIBUTES_100G,
  NUTRITION_ATTRIBUTES,
  NUTRITION_DISCLAIMER_MESSAGES,
  NUTRITION_PROTEIN_KEY,
  NUTRITION_STYLE_KEY,
  NUTRITION_UNITS_100G,
  NUTRITION_UNITS,
  ORDER_HELP_FLOW_STEPS,
  ORDER_HELP_FLOW_STEPS_BY_ISSUE_TYPE,
  ORDER_ITEM_ISSUES,
  OFFER_TYPES_LABELS,
  OFFER_TYPES,
  ORDER_ISSUE_TYPES,
  ORDER_STATUS,
  ORDER_ACTION_TYPES,
  ORDER_TIMES,
  ORDER_TYPES_LABELS,
  ORDER_TYPES,
  ORDER_UPDATE_STATUS,
  ORDERING_CONTEXT_KEY,
  PAYMENT_METHOD_LABELS,
  PAYMENT_METHODS,
  PAYMENT_TYPES,
  PERKS_SHOP_REQUEST_URI,
  PERKS_SHOP_STORE_ID,
  PICKUP_TIME_INTERVAL,
  PLATFORMS,
  PLAY_STORE_URLS,
  PREVIOUS_CART_KEY,
  PRODUCT_BUTTON_LABELS,
  PRODUCT_EXISTS_IN_CART_KEY,
  PRODUCT_ID_TO_PLU_REG_EX,
  PRODUCT_MODIFIERS,
  RECOMMENDATION_ENGINE,
  RECEIPT_TYPES,
  REFUND_REFUSAL_REASONS,
  REFUND_TYPES,
  REFUND_LOG_ENTRY_PK,
  RESTAURANT_CLOSING_OFFSET_IN_MINUTES_BY_ORDER_TYPE,
  SAVED_DIETARY_PREFERENCES_KEY,
  SCHEDULE_TYPES,
  SIDE_AND_DRINK_OPTIONS_REQUIRED_PRODUCT_DISCLAIMER,
  SIDE_AND_SNACK_PRODUCT_DISCLAIMER,
  SIDE_OPTIONS_REQUIRED_PRODUCT_DISCLAIMER,
  SPIN_TO_WIN_WHEEL_SEGMENTS,
  SUITABILITY_ATTRIBUTES,
  URL,
  WEBSOCKET_DATA_TYPE,
  ANALYTICS_EVENTS,
  ZENDESK_TICKET_STATUS,
  SURCHARGE_TYPE,
  SUPPORT_TICKET_FLOW,
  WARNING_AGE_YEARS,
  HALF_WIDTH_MOBILE_CATEGORY_HANDLES,
  INCLUDED_CATEGORIES_HANDLES_ORDER,
  MENU_CATEGORY_COLOURS,
  MENU_CATEGORY_FLAME_COLOURS,
  MENU_CATEGORY_IMAGE_SRC,
  GROUP_ORDER_TYPES,
  UPSELL_ITEM_MAX_QUANTITY,
  SIGN_UP_VERIFICATION_DISCLAIMER,
};
