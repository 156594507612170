import { useContext } from 'react';
import { isBoolean, isEmpty, isUndefined } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ServerSideFeatureFlagsContext } from '@components/feature-provider';
import { logger } from '../../utils/logger';

const useFeature = key => {
  const serverSideFeatureFlags = useContext(ServerSideFeatureFlagsContext);
  const ldFlags = useFlags();

  const hasLaunchDarklyFlags = !isEmpty(ldFlags);
  const flags = hasLaunchDarklyFlags ? ldFlags : serverSideFeatureFlags;

  const flag = flags[key];

  if (isUndefined(flag)) {
    // Don't show this warning if the flags are still loading
    // Explicitly do not throw this warning if we are in a Jest environment, stolen from:
    // https://stackoverflow.com/questions/50940640/how-to-determine-if-jest-is-running-the-code-or-not
    if (!isEmpty(flags) && process?.env?.JEST_WORKER_ID === undefined) {
      logger.warn('Feature flag is undefined', { key });
    }
    return false;
  }

  if (!isBoolean(flag) && isEmpty(flag)) {
    return false;
  }

  return flag;
};

export { useFeature };
