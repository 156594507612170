import { isEmpty } from 'lodash';
import { flow, getEnv, types } from 'mobx-state-tree';

import { getFeatureFlags } from './get-feature-flags';

const FeatureFlagStore = types
  .model('FeatureFlagStore', {
    features: types.optional(types.frozen(), {}),
    serverSideFeatureFlags: types.optional(types.frozen(), {}),
    hasError: types.optional(types.boolean, false),
  })
  .actions(self => {
    const { getApiClient, logger, platform } = getEnv(self);

    return {
      loadServerSideFeatureFlags: flow(function*() {
        const client = yield getApiClient();
        const featureFlags = yield getFeatureFlags({ client, logger, platform });
        self.setServerSideFeatureFlags(featureFlags);

        return featureFlags;
      }),

      setServerSideFeatureFlags: flags => {
        logger.info('Setting server side feature flags', { flags });
        self.serverSideFeatureFlags = flags;
      },

      setHasError: hasError => {
        self.hasError = hasError;
      },

      set: features => {
        self.features = {
          ...self.features,
          ...features,
        };
      },
    };
  })
  .views(self => ({
    isActive(flag) {
      return self.features[flag] || self.serverSideFeatureFlags[flag] || false;
    },

    get featureFlags() {
      const hasClientSideFlags = !isEmpty(self.features);
      if (hasClientSideFlags) {
        return self.features;
      }
      return self.serverSideFeatureFlags;
    },

    get hasFeatureFlags() {
      return !isEmpty(self.features) || !isEmpty(self.serverSideFeatureFlags);
    },
  }));

export default FeatureFlagStore;
