import {
  ALCOHOL_WITH_FOOD_ERROR_MESSAGE,
  ANALYTICS_EVENTS,
  CONNECTION_ERRORS,
  MENU_CATEGORIES,
  ORDER_STATUS,
  ORDER_TIMES,
  ORDER_TYPES,
  PAYMENT_METHODS,
  PAYMENT_TYPES,
  PRODUCT_ID_TO_PLU_REG_EX,
  SCHEDULE_TYPES,
  SURCHARGE_TYPE,
} from '@nandosaus/constants';
import gql from 'graphql-tag';
import {
  concat,
  each,
  endsWith,
  entries,
  filter,
  find,
  first,
  flatten,
  forEach,
  get,
  includes,
  intersection,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  isString,
  join,
  last,
  lowerCase,
  map,
  min,
  omit,
  reduce,
  reject,
  round,
  set,
  size,
  some,
  split,
  startCase,
  sumBy,
  values,
  without,
} from 'lodash';
import { autorun, reaction, when } from 'mobx';
import { flow, getEnv, getRoot, getSnapshot, resolveIdentifier, types } from 'mobx-state-tree';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import GuestCheckoutCustomerDetails from '../../models/guest-checkout-customer-details';
import Offer from '../../models/offer';
import Option from '../../models/option';
import Payment from '../../models/payment';
import Product from '../../models/product';
import {
  DEFAULT_SUB_CART,
  DEFAULT_SUB_CART_ID,
  getFilledAsList as getFilledSubCartsAsList,
  getIds as getSubCartIds,
  getOrderItems as getSubCartOrderItems,
  SubCarts,
} from '../../models/sub-cart';
import Surcharge from '../../models/surcharge';
import ValidationError from '../../models/validation-error';
import { formatOrderItemForProduct, formatSubCartsForProducts } from '../../util/analytics';
import { priceFormatter } from '../../util/formatter';
import { calculateDiscountPrices } from '../../util/payment/calculate-discount-prices';
import { calculateSubtotalPrices } from '../../util/payment/calculate-subtotal-prices';
import { calculateSurcharges } from '../../util/payment/calculate-surcharges';
import { getElapsedTimeInMinutes, getElapsedTimeInSeconds } from '../../util/time';

const createInitialState = () => {
  const cartId = uuidv4();
  return {
    cartId,
    notes: '',
    offer: undefined,
    subCarts: { [DEFAULT_SUB_CART_ID]: DEFAULT_SUB_CART },
    removedSubCarts: {},
    payment: undefined,
    paymentData: undefined,
    paymentTypes: [],
    submitted: false,
    upsellModalShown: false,
    guestCheckoutCustomerDetails: undefined,
    groupInviteeSubmissionToken: undefined,
    isGuestCheckoutFlow: false,
    surcharges: [],
  };
};

const initialState = createInitialState();

const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($input: OrderInput!) {
    createOrder(input: $input) {
      action
      deliveryToken
      deliveryTrackingUrl
      estimatedDeliveryTime
      message
      orderId
      orderReference
      paymentData
      pickupTime
      success
    }
  }
`;

const VALIDATE_ORDER_QUERY = gql`
  query ValidateOrder($input: OrderInput!) {
    validateOrder(input: $input) {
      message
      success
    }
  }
`;

const CREATE_GIFT_CARD_ORDER_MUTATION = gql`
  mutation CreateGiftCardOrder($input: GiftCardOrderRequest!) {
    createGiftCardOrder(input: $input) {
      action
      paymentData
      message
      orderReference
      success
    }
  }
`;

const mapProductIdToMenu = (productId, menuId) => {
  const matches = productId.match(PRODUCT_ID_TO_PLU_REG_EX);
  const correspondingProductId = `${menuId}-${matches[1]}`;

  return correspondingProductId;
};

const CartStore = types
  .model('CartStore', {
    knownSubcartIdMap: types.optional(types.map(types.boolean), { [DEFAULT_SUB_CART_ID]: true }),
    loading: false,
    error: false,
    hasNetworkError: false,
    errors: types.optional(types.array(ValidationError), []),
    // @TODO: Move to alerts: Alert model if we add Warning.
    submitted: false,
    errorMessage: '',
    cartId: types.string,
    notes: types.string,
    // During group ordering, the host's cart store will hold multiple SubCarts for different users
    subCarts: SubCarts,
    removedSubCarts: SubCarts,
    offer: types.maybeNull(
      types.reference(Offer, {
        get(identifier, parent) {
          return parent.allOffers.find(u => u.id === identifier) || null;
        },
        set(value) {
          return value.id;
        },
      })
    ),
    payment: types.optional(Payment, {
      // paymentMethod: (tokenised, new credit card, undefined)
      // storePaymentMethod
    }),
    paymentTypes: types.maybe(types.array(types.enumeration(Object.keys(PAYMENT_TYPES)))),
    paymentData: types.maybe(types.string),

    // Warnings:
    warnOfferProduct: false,
    warnOfferRestaurant: false,
    // @TODO: Make these views?
    // warnRestaurantRequired: false,
    // warnOrderItemRequired: false,

    restaurantLastModified: types.maybe(types.Date),

    menuVersion: types.maybe(types.string),
    upsellModalShown: false,

    defaultOrderEstimate: types.maybe(types.number),
    isGuestCheckoutFlow: false,
    guestCheckoutCustomerDetails: types.maybe(GuestCheckoutCustomerDetails),
    surcharges: types.optional(types.array(Surcharge), []),
  })
  .actions(self => {
    const {
      AlertsStore,
      LocationStore,
      MemberStore,
      MenuStore,
      OrderStore,
      OrderingContextStore,
      RestaurantStore,
      DeliveryStore,
      RecommendationEngineStore,
      GroupOrderStore,
    } = getRoot(self);
    const { analytics, logger } = getEnv(self);

    autorun(() => {
      if (!self.isInSyncWithMenu) {
        self.syncWithMenu(MenuStore.menu);
      }
    });

    reaction(
      () => concat(...values(omit(self.toOrderInput(), ['surcharges'])), self.paymentTypes, self.isInSyncWithMenu),
      () => {
        if (!self.isInSyncWithMenu) {
          return;
        }

        self.validateOrderIfOrderItems();
      }
    );

    /**
     * Whenever the customer's current location changes check whether we should sync the
     * order restaurant with their location and if so then perform a sync.
     */
    reaction(
      () => [LocationStore.latitude, LocationStore.longitude],
      ([latitude, longitude]) => {
        if (latitude === undefined || longitude === undefined || self.itemCount > 0) {
          return;
        }

        const { restaurantLastModified } = self;
        const isStale = restaurantLastModified === undefined || moment().diff(restaurantLastModified, 'minutes') >= 60;

        if (!isStale) {
          return;
        }

        self.syncRestaurantWithLocation({
          latitude,
          longitude,
        });
      }
    );

    /**
     * this catches an edge case where the user may have configured a split payment of (for example) google pay and gift card,
     * then gone back and modified their cart such that the gift card can now pay the full amount.
     * triggering setSplitPaymentType will check for this, and remove the superflous google pay payment type.
     */
    reaction(
      () => get(self, 'totalPrices.cents'),
      () => {
        if (self.hasPaymentType(PAYMENT_TYPES.GIFT_CARD)) {
          self.setSplitPaymentType(PAYMENT_TYPES.GIFT_CARD);
        }
      }
    );

    return {
      /**
       * Sync all items in the cart with a particular menu, ensuring that prices
       * and other attributes correspond to those in the menu.
       * @param {object} menu
       */
      syncWithMenu: flow(function*(menu) {
        if (menu === undefined) {
          return;
        }

        /*
          If there are no items in the cart then synching can be skipped.
        */
        if (self.orderItems.length === 0) {
          self.menuVersion = menu.version;
          return;
        }

        /*
        First task is to lazy-load any products which exist in the customer's cart but can't be found
        in the menu. These may be other sizes of products (e.g. Large Chips) which aren't included
        in the initial menu fetch for NZ restaurants.
        */
        const missingProducts = self.orderItems
          .filter(orderItem => orderItem.product)
          .filter(orderItem => menu.hasProduct(mapProductIdToMenu(orderItem.product.id, menu.id)))
          .map(orderItem => mapProductIdToMenu(orderItem.product.id, menu.id))
          .filter(productId => !menu.productLoaded(productId));

        if (missingProducts.length > 0) {
          try {
            yield menu.loadProducts(missingProducts);
          } catch (error) {
            /*
              If loading the missing products failed then log an error and continue. Any products which couldn't
              be loaded will be removed from the customer's cart in the second task below.
            */
            logger.error('Failed to get detailed product data', {
              error,
              orderType: menu.orderType,
              productIds: missingProducts,
              restaurantId: menu.restaurantId,
            });
          }
        }

        const removedItems = [];

        self.subCartIds.forEach(subCartId => {
          /*
            Second task is to iterate over all items in the customer's cart and migrate to the corresponding
            product in the menu being synced. If a product cannot be found then an alert is displayed.
          */
          const newItems = [];

          const { orderItems } = self.subCarts.get(subCartId);
          orderItems.forEach(orderItem => {
            if (isNil(orderItem.product)) {
              logger.warn('Failed to find corresponding product for', orderItem);

              /**
               * The removedItems alert logc works well when migrating between two menus as the old product name is available in state
               * However, when the current menu updates and a product is removed, the product name is unavailable as the product is now longer in state
               *
               * @TODO replace with something more user friendly or refactor to run this logic before the old menu is removed
               */
              removedItems.push('Unknown Product');
              return;
            }

            const newProductId = mapProductIdToMenu(orderItem.product.id, menu.id);
            const newProduct = menu.getProductById(newProductId);

            if (!newProduct || !newProduct.isAvailable) {
              removedItems.push(orderItem.product.name);
              logger.warn('Failed to find corresponding product for', orderItem.product.name);
              return;
            }

            newItems.push(...orderItem.migrateTo(newProduct));
          });

          self.updateOrderItems(newItems, subCartId);
        });

        if (removedItems.length !== 0) {
          AlertsStore.add({
            body: `Some items were removed from your cart because they’re not available for ${
              self.formattedOrderType
            } from your selected restaurant:\r\n\r\n${removedItems.join('\r\n')}`,
            title: 'Your order has been updated',
            dismissText: 'Return to menu',
          });
        }

        /*
          Once the cart and menu are in sync we update `menuVersion` to match the synced menu. This
          allows cart validation to be deferred until synchronisation is complete.
        */
        self.menuVersion = menu.version;
      }),

      syncRestaurantWithLocation(location) {
        const closestRestaurant = RestaurantStore.closestRestaurant(location);

        if (!closestRestaurant) {
          return;
        }

        self.updateRestaurant(closestRestaurant);
      },

      setError(displayError) {
        self.error = displayError;
      },
      setErrorMessage(errorMessage) {
        self.error = true;
        self.errorMessage = errorMessage;
      },
      setShowCartMap(showMap) {
        logger.info('Deprecated: use context store directly', 'set showMap');
        OrderingContextStore.updateSettings({ showMap });
      },
      addOrderItem({
        product,
        quantity = 1,
        choices,
        notes,
        kilojoules,
        properties = {},
        isReorderItem = false,
        isUpsellItem = false,
        isRecommendedItem = false,
      }) {
        const resolvedProduct = isString(product) ? resolveIdentifier(Product, getRoot(self), product) : product;
        const options = flatten(values(choices));
        const resolvedOptions = map(options, ({ option }) => resolveIdentifier(Option, getRoot(self), option));
        const resolvedSelectedItems = map(concat(resolvedProduct, resolvedOptions), ({ name, isAvailable }) => ({
          name,
          isAvailable,
        }));
        const unavailableSelectedItems = reject(resolvedSelectedItems, 'isAvailable');

        if (some(unavailableSelectedItems)) {
          AlertsStore.add({
            body: `Some items haven't been to added to your cart because they are currently unavailable from your selected restaurant:\r\n\r\n${map(
              unavailableSelectedItems,
              'name'
            ).join('\r\n')}`,
            title: 'Item unavailable',
            dismissText: 'Return to menu',
          });

          logger.warn('addOrderItem called for an unavailable product or choice', {
            body: JSON.stringify({ product, quantity, choices, notes, kilojoules, properties }),
          });

          return;
        }

        const defaultSubCart = self.subCarts.get(DEFAULT_SUB_CART_ID);
        defaultSubCart.orderItems.push({
          choices,
          notes,
          product,
          quantity,
          kilojoules,
          isReorderItem,
          isUpsellItem,
          isRecommendedItem,
        });

        // get full orderItem model in order to use product reference
        const orderItemModel = last(self.orderItems);

        const analyticsOrderItem = formatOrderItemForProduct(orderItemModel, {
          subCart: defaultSubCart,
          orderType: self.orderType,
        });

        analytics.track(ANALYTICS_EVENTS.PRODUCT_ADDED, analyticsOrderItem);

        if (RecommendationEngineStore.addedRecommendationsToCart) {
          analytics.track(ANALYTICS_EVENTS.RECOMMENDED_CART_ADJUSTED_ADDED, {
            product: analyticsOrderItem,
            cartId: self.cartId,
          });
        }
      },
      addOrderItems(orderItems) {
        forEach(orderItems, self.addOrderItem);
      },

      /**
       * Clear this Store's data back to it's initialState.
       */
      clear() {
        // @TODO: Determine if restaurant was deliberately not cleared (or if that was an oversight).
        const state = createInitialState();
        Object.assign(self, state);
      },

      /**
       * Submits the data in the CartStore to the Nando's API to create an order.
       * Populates OrderConfirmationState on success.
       * Populates CartStore error attributes on failure.
       * TODO: Move this into OrderStore
       */
      createOrder: flow(function*(input) {
        const { paymentDetails, sessionStoragePayableTotal } = input || {};
        const isGroupOrderInvitee = GroupOrderStore.isGroupInvitee;
        const isGroupOrderHost = GroupOrderStore.isHost;
        if (OrderStore.status !== ORDER_STATUS.PAYMENT_CHALLENGED) {
          OrderStore.setStatus(ORDER_STATUS.PROCESSING);
        }

        self.submitted = false;
        self.error = false;
        self.loading = true;

        const { getApiClient } = getEnv(self);
        try {
          if (sessionStoragePayableTotal) {
            const orderStorePayableTotal = getSnapshot(OrderStore.payableTotal);
            if (!isEqual(sessionStoragePayableTotal, orderStorePayableTotal)) {
              logger.error('Order total cost did not match after session storage reload', {
                sessionStoragePayableTotal,
                orderStorePayableTotal,
              });
              throw new Error('Order total cost did not match after session storage reload');
            }
          }
          const client = yield getApiClient();
          const createOrderInput = self.toCreateOrderInput({ paymentDetails });

          const createOrderResponse = yield client.mutate({
            mutation: CREATE_ORDER_MUTATION,
            variables: {
              input: createOrderInput,
            },
          });

          self.loading = false;

          const action = get(createOrderResponse, 'data.createOrder.action');

          if (action) {
            if (OrderStore.status === ORDER_STATUS.PAYMENT_CHALLENGED || action.type === 'threeDS2Challenge') {
              OrderStore.setStatus(ORDER_STATUS.PAYMENT_ACTION_REQUIRED);
            } else {
              OrderStore.setStatus(ORDER_STATUS.PAYMENT_CHALLENGED);
            }

            return { action, submitted: false };
          }

          const success = get(createOrderResponse, 'data.createOrder.success');

          if (!success) {
            const errorMessage = get(createOrderResponse, 'data.createOrder.message');

            if (errorMessage) {
              self.payment.setErrorMessage(errorMessage);
              OrderStore.setStatus(ORDER_STATUS.PAYMENT_ERROR);
            } else {
              OrderStore.setStatus(ORDER_STATUS.FATAL_ERROR);
            }

            self.error = true;

            return { action: undefined, submitted: false };
          }

          const {
            deliveryToken,
            deliveryTrackingUrl,
            estimatedDeliveryTime,
            orderId,
            orderReference,
            pickupTime,
          } = createOrderResponse.data.createOrder;

          const { OrderConfirmationState } = getRoot(self);

          // these fields are not provided by the API for group order invitee submissions
          const groupOrderInviteeOverrides = GroupOrderStore.isGroupInvitee
            ? { orderId: undefined, orderReference: undefined }
            : {};

          OrderConfirmationState.update({
            orderId,
            restaurant: self.restaurantId,
            pickupTime,
            estimatedDeliveryTime,
            deliveryToken,
            deliveryTrackingUrl,
            orderType: self.orderType,
            orderPickupType: OrderingContextStore.fulfilmentOrEmpty.schedule,
            orderReference,
            subCarts: self.subCarts.toJSON(),
            fulfilment: OrderingContextStore.fulfilment.toJSON(),
            ...groupOrderInviteeOverrides,
          });

          if (isGroupOrderInvitee) {
            analytics.track(ANALYTICS_EVENTS.GROUP_ORDER_INVITEE_SUBMITTED, {
              cartId: self.cartId,
              groupId: GroupOrderStore.id,
              value: self.totalPrices.dollarValue,
              itemCount: self.orderItems.length,
            });
          } else {
            analytics.track(ANALYTICS_EVENTS.ORDER_COMPLETED, {
              cartId: self.cartId,
              total: self.totalPrices.dollarValue,
              coupon: self.offer ? self.offer.description : undefined,
              shipping: OrderStore.deliveryCost ? OrderStore.deliveryCost.dollarValue : 0,
              paymentType: join(map(get(self, 'paymentTypes'), paymentType => startCase(lowerCase(paymentType)))),
              products: formatSubCartsForProducts(self.subCarts, {
                orderType: self.orderType,
                group: isGroupOrderHost ? GroupOrderStore.group : undefined,
              }),
            });
          }

          if (isGroupOrderHost) {
            analytics.track(ANALYTICS_EVENTS.GROUP_ORDER_COMPLETED, {
              cartId: self.cartId,
              groupId: GroupOrderStore.id,
              groupMemberCount: self.subCartsAsArray.length,
              restaurantId: self.restaurantId,
              timeToPurchaseTotalInMinutes: getElapsedTimeInMinutes(GroupOrderStore.requestTime),
              timeToPurchaseTotalInSeconds: getElapsedTimeInSeconds(GroupOrderStore.requestTime),
              value: self.totalPrices.dollarValue,
            });
          }

          if (RecommendationEngineStore.addedRecommendationsToCart) {
            RecommendationEngineStore.setProp('addedRecommendationsToCart', false);

            analytics.track(ANALYTICS_EVENTS.RECOMMENDED_CART_PURCHASED, {
              cartId: self.cartId,
              timeToPurchaseTotalInSeconds: getElapsedTimeInSeconds(RecommendationEngineStore.requestTime),
              timeToPurchaseTotalInMinutes: getElapsedTimeInMinutes(RecommendationEngineStore.requestTime),
              total: self.totalPrices.dollarValue,
              orderType: self.orderType,
            });
          }

          OrderStore.setStatus(ORDER_STATUS.SUBMITTED);
        } catch (error) {
          const errorMessage = get(error, 'errors[0].message');

          if (includes([CONNECTION_ERRORS.FAILED_CODE_504, CONNECTION_ERRORS.NETWORK_ERROR], errorMessage)) {
            OrderStore.setStatus(ORDER_STATUS.NETWORK_ERROR);
          } else {
            OrderStore.setStatus(ORDER_STATUS.FATAL_ERROR);
          }

          logger.error('CartStore createOrder failed', { error });
          self.error = true;
          self.loading = false;
        }

        self.submitted = !self.error;

        // Fetch the profile to update points.
        if (!self.isGuestCheckoutFlow && MemberStore.isSignedIn) {
          MemberStore.loadProfile();
        }

        return {
          action: undefined,
          submitted: self.submitted,
        };
      }),

      createGiftCardOrder: flow(function*(rawInput, adyenNativeResponse) {
        if (OrderStore.status !== ORDER_STATUS.PAYMENT_CHALLENGED) {
          OrderStore.setStatus(ORDER_STATUS.PROCESSING);
        }

        self.submitted = false;
        self.error = false;
        self.loading = true;

        const { getApiClient } = getEnv(self);
        try {
          const client = yield getApiClient();

          const paymentFields = self.buildPaymentFields(adyenNativeResponse, false);
          const input = {
            reference: uuidv4(),
            givenNames: rawInput.sendersGivenName,
            surname: rawInput.sendersSurname,
            email: rawInput.sendersEmail,
            recipientEmail: rawInput.recipientsEmail,
            recipientName: rawInput.recipientsGivenName,
            recipientSurname: rawInput.recipientsSurname,
            orderTotal: rawInput.amount * rawInput.quantity * 100,
            message: rawInput.message || 'todo',
            alternativePaymentMethod: get(self, 'payment.alternativePaymentMethod'),
            browserInfo: get(self, 'payment.browserInfo'),
            riskData: get(self, 'payment.creditCard.riskData'),
            ...omit(paymentFields, 'orderTotalSplitArray'),
            paymentType: first(self.paymentTypes), // gift card purchases can only have 1 payment type
          };

          const response = yield client.mutate({ mutation: CREATE_GIFT_CARD_ORDER_MUTATION, variables: { input } });
          const { success, action, message, orderReference } = get(response, 'data.createGiftCardOrder');
          self.loading = false;
          if (action) {
            if (OrderStore.status === ORDER_STATUS.PAYMENT_CHALLENGED || action.type === 'threeDS2Challenge') {
              OrderStore.setStatus(ORDER_STATUS.PAYMENT_ACTION_REQUIRED);
            } else {
              OrderStore.setStatus(ORDER_STATUS.PAYMENT_CHALLENGED);
            }

            return { action, submitted: false };
          }
          if (!success) {
            const errorMessage = message;

            if (errorMessage) {
              self.payment.setErrorMessage(errorMessage);
              OrderStore.setStatus(ORDER_STATUS.PAYMENT_ERROR);
            } else {
              OrderStore.setStatus(ORDER_STATUS.FATAL_ERROR);
            }

            self.error = true;

            return { action: undefined, submitted: false };
          }

          const { OrderConfirmationState } = getRoot(self);
          OrderConfirmationState.update({
            orderReference,
            recipientsEmail: rawInput.recipientsEmail,
            subCarts: self.subCarts.toJSON(),
          });
          OrderStore.setStatus(ORDER_STATUS.SUBMITTED);
          self.submitted = !self.error;
          return { action: undefined, submitted: self.submitted, orderReference };
        } catch (error) {
          const errorMessage = get(error, 'errors[0].message');

          if (includes([CONNECTION_ERRORS.FAILED_CODE_504, CONNECTION_ERRORS.NETWORK_ERROR], errorMessage)) {
            OrderStore.setStatus(ORDER_STATUS.NETWORK_ERROR);
          } else {
            OrderStore.setStatus(ORDER_STATUS.FATAL_ERROR);
          }

          logger.error('CartStore createGiftCardOrder failed', { error });
          self.error = true;
          return { action: undefined, submitted: false, errorMessage };
        }
      }),

      validateOrderIfOrderItems: () => {
        if (self.orderItems.length > 0) {
          self.validateOrder();
        }
      },

      /**
       * Perform validation of the order via Nando's API. Any errors that are found
       * during validation will be available via OrderStore.validationErrors.
       *
       * TODO: There's a couple bits of client-side validation in here. They should be moved.
       */
      validateOrder: flow(function*() {
        if (self.loading) {
          return {
            loading: self.loading,
            error: self.error,
          };
        }

        if (self.isDelivery) {
          yield when(() => !DeliveryStore.loading);
        }

        /*
          Skip validation if the customer's cart is out of sync with the current menu
          because it is likely to fail.
        */
        if (!self.isInSyncWithMenu) {
          return { loading: self.loading, error: self.error };
        }

        const { getApiClient } = getEnv(self);

        /**
         * Reset error state whilst validating to ensure consistency.
         *
         * We're validating because something on the order has changed therefore the
         * errors may no longer be relevant, however they would be displayed until
         * validation finishes unless we clear them.
         */
        self.errors = [];
        self.errorMessage = '';
        self.error = false;

        /**
         * * TODO: Move this into OrderStore.clientSideErrors
         */
        if (self.orderType === ORDER_TYPES.PICK_UP && self.restaurant !== undefined) {
          const orderEstimate = moment(new Date()).add(self.orderEstimateMinutes, 'm');
          try {
            const isAsap = self.orderTime === ORDER_TIMES.ASAP;

            const pickUpTime = self.toPickUpTime();

            // If this order is scheduled, we meed to check that the requested pickUpTime is after the orderEstimate time.
            if (!isAsap && pickUpTime.isBefore(orderEstimate)) {
              throw new Error(
                'Unfortunately, the restaurant will not be able to complete this order by the requested time. Please allow more time for this order.'
              );
            }

            if (isAsap) {
              // If an ASAP order is requested, we need to make sure the restaurant
              // will still be open when the pick up order is scheduled to be ready.

              const { closingTime } = self.restaurant.tradingHoursForPointInTime(orderEstimate);

              if (orderEstimate.isSameOrAfter(moment(closingTime))) {
                // the store will be closed when the order is complete.
                throw new Error('Unfortunately, the restaurant will not be able to complete this order today.');
              }
            }
          } catch (err) {
            self.errors = [{ template: err.message }];
            self.error = true;

            return {
              loading: self.loading,
              error: self.error,
            };
          }
        }

        /**
         * When an offer is applied, enforce the offer's minimum spend required
         * TODO: Move this into OrderStore.clientSideErrors
         */
        if (self.offer && OrderStore.subTotal.cents < self.offer.minimumOrderSpend) {
          self.errors = [{ template: 'Please add a Main item to your order' }];
          self.error = true;

          return {
            loading: self.loading,
            error: self.error,
          };
        }

        /**
         * If the current menu is unknown then we should skip validation since we don't yet know
         * whether the items in the customer's cart are valid. This can occur when loading a new
         * menu (e.g. switching from pick-up to delivery).
         *
         * If there are any client-side errors then we also skip validation since we already
         * know that the validation call will fail.
         */
        if (MenuStore.menu === undefined || OrderStore.clientSideErrors.length > 0) {
          return {
            loading: self.loading,
            error: self.error,
          };
        }

        // handling for WA where food is necessary to qualify an alcohol purchase
        const isAlcoholWithFoodValid = self.validateAlcoholWithFoodRestriction();

        if (!isAlcoholWithFoodValid) {
          analytics.track(ANALYTICS_EVENTS.ALCOHOL_RESTRICTION_TRIGGERED, {
            products: formatSubCartsForProducts(self.subCarts, {
              orderType: self.orderType,
              group: GroupOrderStore.group,
            }),
            restaurant_id: self.restaurantId,
          });

          self.errors = [{ template: ALCOHOL_WITH_FOOD_ERROR_MESSAGE }];
          self.error = true;

          return {
            loading: self.loading,
            error: self.error,
          };
        }

        self.hasNetworkError = false;
        self.loading = true;

        try {
          const client = yield getApiClient();

          self.surcharges = yield calculateSurcharges({
            client,
            input: self.toCalculateSurchargesInput(),
          });

          const validateOrderInput = self.toValidateOrderInput();

          yield client.query({
            query: VALIDATE_ORDER_QUERY,
            variables: {
              input: validateOrderInput,
            },
          });
        } catch (validateOrderResponse) {
          const errors = [];

          // Iterate through and get each top level error if it has no children errors,
          // or just the children errors if it has children.

          // @TODO: Move some of this data fetching to the API?
          const addError = ({ message, extensions }) => {
            const path = get(extensions, 'path', 'unknown');
            const productId = get(extensions, 'productId');
            const code = get(extensions, 'code');

            if (productId) {
              // Setting friendlyName to a default string.
              // Combat an un-resolved race condition where validation errors did not have a value for friendlyName,
              // likely caused by the orderItem products migrating to the new menu, but an old validateOrder call
              // response coming in with errors for productIds that have since changed.
              // As such, the validation errors will be replaced.
              set(extensions, 'friendlyName', 'A product or option');

              each(self.orderItems, orderItem => {
                // Case that productId is referencing a product.
                if (endsWith(orderItem.product.id, productId)) {
                  set(extensions, 'product', orderItem.product);
                  set(extensions, 'option', undefined);
                  set(extensions, 'friendlyName', orderItem.product.name);
                }
                // Case that productId is referencing a product option.
                let errorOption;
                orderItem.choices.forEach(options => {
                  const foundOption = find(options, ({ option }) => option.id === productId);
                  if (foundOption) {
                    errorOption = foundOption;
                  }
                });
                if (errorOption) {
                  set(extensions, 'product', orderItem.product);
                  set(extensions, 'option', errorOption);
                  set(extensions, 'friendlyName', `${errorOption.name} (in ${orderItem.product.name})`);
                }
              });
            }

            const plus = get(self, 'offer.plus');
            if (plus) {
              const productNames = [];
              each(plus, plu => {
                const product = MenuStore.getProductByPartialId(plu);
                if (product) {
                  productNames.push(product.name);
                }
              });
              set(extensions, 'productNames', productNames.join('\n'));
            }

            const foundProductsForOffer = !isEmpty(get(extensions, 'productNames'));
            const foundExtrasForOffer = get(self, 'offer.isForExtras');
            const offerProductsNotFoundInMenu = !isEmpty(plus) && !foundProductsForOffer && !foundExtrasForOffer;

            const template = (() => {
              if (code === 'OFFER_NOT_APPLICABLE_PLU') {
                if (offerProductsNotFoundInMenu) {
                  return 'Applied offer cannot be used at your current restaurant location';
                }
                if (foundExtrasForOffer) {
                  return 'Offer not applicable, please add a product extra supported by the offer';
                }
              }

              return get(extensions, 'template', message);
            })();

            if (code === 'OFFER_MIN_VALUE_NOT_MET') {
              set(extensions, 'minOrderValue', get(self, 'offer.minOrderValue'));
              set(extensions, 'discountAmount', priceFormatter(get(self, 'discountPrices.cents')));
            }

            const restaurantIds = get(self, 'offer.restaurantIds');
            if (restaurantIds) {
              const restaurantNames = [];
              each(restaurantIds, restaurantId => {
                const restaurant = RestaurantStore.restaurants.get(restaurantId);
                if (restaurant) {
                  restaurantNames.push(restaurant.name);
                }
              });
              set(extensions, 'restaurantNames', restaurantNames.join('\n'));
            }

            if (message === CONNECTION_ERRORS.NETWORK_ERROR) {
              self.hasNetworkError = true;
            } else {
              errors.push({
                extensions: omit(extensions, ['message', 'errors', 'path', 'template']),
                message,
                path,
                template,
              });
            }
          };

          if (isArray(validateOrderResponse.errors)) {
            validateOrderResponse.errors.forEach(error => {
              if (isArray(get(error, 'extensions.errors'))) {
                error.extensions.errors.forEach(addError);
              } else {
                addError(error);
              }
            });
          } else {
            logger.error('validateOrder failed', { validateOrderResponse });

            addError(validateOrderResponse);
          }

          self.errors = errors;
          self.error = true;
        }

        self.loading = false;

        return {
          loading: self.loading,
          error: self.error,
        };
      }),
      updateRestaurant: restaurant => {
        self.restaurant = restaurant;
        self.restaurantLastModified = new Date();
      },
      updateOrderType: orderType => {
        self.orderType = orderType;
      },
      updateTableNumber: table => {
        self.tableNumber = table;
      },
      validateAlcoholWithFoodRestriction() {
        const { FeatureFlagStore } = getRoot(self);

        const alcoholWithFoodFlag = FeatureFlagStore.isActive('alcohol-with-food-wa');

        if (!alcoholWithFoodFlag || isEmpty(alcoholWithFoodFlag)) {
          return true;
        }

        if (self.restaurant?.address?.state !== 'WA') {
          return true;
        }

        /* meals products do not have a productCategory so they are converted to a single item
           version using a function called "itemProduct" which returns a product that does have a category */

        const orderItemsAsSingleProducts = map(self.orderItems, orderItem => {
          const { isMealProduct, itemProduct } = orderItem.product;
          // if order is a meal, convert it to single item to grab the category
          if (isMealProduct && itemProduct.productCategory) {
            return itemProduct;
          }
          return orderItem.product;
        });

        const isAlcohol = product => product?.productCategory?.handle === MENU_CATEGORIES.ALCOHOL;

        const includesAlcoholProduct = orderItemsAsSingleProducts.some(isAlcohol);

        if (!includesAlcoholProduct) {
          return true;
        }

        const excludedCategories = alcoholWithFoodFlag.excludedCategories ?? [];
        const excludedPLUs = alcoholWithFoodFlag.excludedPLUs ?? [];

        const isAlcoholPurchaseQualifying = product => {
          // if items are excluded from qualifying an alcohol purchase as 'food', return false
          if (excludedPLUs.includes(product.plu)) {
            return false;
          }

          if (excludedCategories.includes(product.productCategory.handle)) {
            return false;
          }

          if (isAlcohol(product)) {
            return false;
          }

          return true;
        };

        // if no items qualify the alcohol purchase, show error as there are insubstantial food items
        if (!orderItemsAsSingleProducts.some(isAlcoholPurchaseQualifying)) {
          return false;
        }

        return true;
      },
      removeSplitPaymentType: paymentType => {
        self.paymentTypes = without(self.paymentTypes, paymentType);
      },
      setSplitPaymentType: paymentType => {
        const selectedGiftCardBalanceCents = get(self, 'payment.giftCard.balance') * 100;
        const giftCardCoversEntireAmount = selectedGiftCardBalanceCents >= self.totalPrices.cents;

        // Clear existing alternative payment method if set
        self.payment.setAlternativePaymentMethod(undefined);

        if (paymentType === PAYMENT_TYPES.LOYALTY) {
          self.paymentTypes = [paymentType]; // points aren't splittable - this is now the only payment type
        } else if (paymentType === PAYMENT_TYPES.GIFT_CARD) {
          if (giftCardCoversEntireAmount) {
            self.paymentTypes = [paymentType];
          } else {
            // apply this paymentType, keeping any dollars types
            self.paymentTypes = [
              ...without(self.paymentTypes, PAYMENT_TYPES.GIFT_CARD, PAYMENT_TYPES.LOYALTY),
              paymentType,
            ];
          }
        } else {
          // in this case new paymentType is a dollars type - apply it, keeping gift card type if split pay possible
          self.paymentTypes =
            !self.hasPaymentType(PAYMENT_TYPES.GIFT_CARD) || giftCardCoversEntireAmount
              ? [paymentType]
              : [paymentType, PAYMENT_TYPES.GIFT_CARD];
        }

        if (!self.hasPaymentType(PAYMENT_TYPES.CREDIT_CARD)) {
          self.payment.setPaymentMethod();
          self.payment.setUseNewCreditCard(false);
        }

        if (paymentType === PAYMENT_TYPES.PAYPAL) {
          self.payment.setAlternativePaymentMethod({ type: PAYMENT_METHODS.PAYPAL, token: 'paypal' });
        }

        analytics.track(ANALYTICS_EVENTS.PAYMENT_INFO_ENTERED, {
          total: self.totalPrices.dollarValue,
          products: formatSubCartsForProducts(self.subCarts, {
            orderType: self.orderType,
            group: GroupOrderStore.group,
          }),
          paymentType: join(map(get(self, 'paymentTypes'), payType => startCase(lowerCase(payType)))),
          coupon: self.offer ? self.offer.description : undefined,
        });
      },
      updateNotes: notes => {
        self.notes = notes;
      },
      updateOffer: offer => {
        self.offer = offer;
      },

      removeOffer() {
        self.offer = undefined;
      },
      findSubCartForOrderItem(orderItem) {
        return self.subCartsAsArray.find(subCart => {
          const subCartContainsOrderItem = subCart.orderItems.findIndex(item => item.id === orderItem.id) !== -1;
          return subCartContainsOrderItem;
        });
      },
      removeOrderItem(orderItem) {
        const subCart = self.findSubCartForOrderItem(orderItem);

        if (subCart === undefined) {
          return;
        }

        const { orderItems } = subCart;
        const index = orderItems.findIndex(item => item.id === orderItem.id);

        // Avoid removing the first item if the orderItem is not found.
        if (index === -1) {
          return;
        }

        const analyticsOrderItem = formatOrderItemForProduct(orderItem, {
          subCart,
          orderType: self.orderType,
          group: GroupOrderStore.group,
        });

        analytics.track(ANALYTICS_EVENTS.PRODUCT_REMOVED, analyticsOrderItem);

        if (RecommendationEngineStore.addedRecommendationsToCart) {
          analytics.track(ANALYTICS_EVENTS.RECOMMENDED_CART_ADJUSTED_REMOVED, {
            product: analyticsOrderItem,
            cartId: self.cartId,
          });
        }

        orderItems.splice(index, 1);
        self.updateOrderItems(orderItems, subCart.id);

        if (self.orderItems.length === 0 && RecommendationEngineStore.addedRecommendationsToCart) {
          RecommendationEngineStore.setProp('addedRecommendationsToCart', false);
        }
      },
      /**
       * Called when the previous cart is saved in localStorage.
       */
      setPreviousCart: flow(function*(previousCart) {
        const now = new Date().getTime();
        const cartTimeToLive = 1000 * 60 * 60 * 12; // 12 hours
        const cartExpiryTime = get(previousCart, 'timestamp', 0) + cartTimeToLive;

        if (now <= cartExpiryTime) {
          try {
            if (!RestaurantStore.loaded) {
              // Load the restaurants to prevent reference issues upon setting the previous cart
              yield RestaurantStore.loadRestaurants();
            }

            const menuRestaurantAndOrderType = split(previousCart.value.menuVersion, ':')[0];
            const menuRestaurantAndOrderTypeSplit = split(menuRestaurantAndOrderType, '-');
            const restaurantId = last(menuRestaurantAndOrderTypeSplit);
            const orderType = menuRestaurantAndOrderType.includes(ORDER_TYPES.DELIVERY)
              ? ORDER_TYPES.DELIVERY
              : ORDER_TYPES.PICK_UP;

            if (restaurantId) {
              // Load detailed menu to prevent reference issues retrieving product data
              yield MenuStore.loadMenu({ restaurantId, orderType });
            }

            if (!isEmpty(self.orderItems)) {
              // User has added items to cart while page was loading - let's not overwrite their cart with one from state
              return;
            }

            Object.assign(self, previousCart.value);
          } catch (error) {
            logger.error(`Error applying previous cart from local storage`, { error });
          }
        }
      }),
      /**
       * Splice an orderItem over the top of an existing orderItem (by id).
       */
      spliceOrderItemById(id, replacementOrderItem) {
        const subCart = self.findSubCartForOrderItem(replacementOrderItem);
        const orderItemIndex = subCart.orderItems.findIndex(iteration => iteration.id === id);
        const { orderItems } = subCart;
        orderItems.splice(orderItemIndex, 1, replacementOrderItem);
        self.updateOrderItems(orderItems, subCart.id);
      },

      updateChoice(orderItem, name, choiceValues) {
        orderItem.choices.set(name, choiceValues);
      },

      updateOrderItems: (orderItems, id) => {
        self.subCarts.set(id, { ...self.subCarts.get(id), orderItems });
      },

      addSubCart: ({ orderItems, id, name }) => {
        self.knownSubcartIdMap.set(id, true);
        self.subCarts.set(id, { orderItems, id, name });
      },

      updateUpsellModalShown() {
        self.upsellModalShown = true;
      },

      updateDefaultOrderEstimate(restaurantId) {
        const { restaurants } = RestaurantStore;
        const restaurant = restaurants.get(restaurantId);
        self.defaultOrderEstimate = get(restaurant, 'averageOrderTime', 15);
      },
      setIsGuestCheckoutFlow(isGuestCheckoutFlow) {
        self.isGuestCheckoutFlow = isGuestCheckoutFlow;
      },
      setGuestCheckoutCustomerDetails(guestCheckoutCustomerDetails) {
        self.guestCheckoutCustomerDetails = guestCheckoutCustomerDetails;
      },
      setGroupInviteeSubmissionToken(groupInviteeSubmissionToken) {
        self.groupInviteeSubmissionToken = groupInviteeSubmissionToken;
      },
      clearRemovedSubCarts() {
        self.removedSubCarts = {};
      },
      attemptMoveSubCarts(fromMap, toMap, subCartIds) {
        if (self.loading) return;

        subCartIds.forEach(cartId => {
          if (!fromMap.get(cartId)) return;

          toMap.set(cartId, fromMap.get(cartId).toJSON());
          fromMap.delete(cartId);
        });
      },
      attemptRemoveSubCarts(subCartIds) {
        self.attemptMoveSubCarts(self.subCarts, self.removedSubCarts, subCartIds);

        if (!self.subCarts.has(DEFAULT_SUB_CART_ID)) {
          self.subCarts.set(DEFAULT_SUB_CART_ID, DEFAULT_SUB_CART);
        }
      },
      attemptUndoRemoveSubCarts(subCartIds) {
        self.attemptMoveSubCarts(self.removedSubCarts, self.subCarts, subCartIds);

        self.syncWithMenu(MenuStore.menu);
      },
      removeAllSubCarts() {
        self.attemptRemoveSubCarts([...self.subCarts.keys()]);
      },
      undoRemoveAllSubCarts() {
        self.attemptUndoRemoveSubCarts([...self.removedSubCarts.keys()]);
      },
      removeSubCart(cartId) {
        self.attemptRemoveSubCarts([cartId]);
      },
      undoRemoveSubCart(cartId) {
        self.attemptUndoRemoveSubCarts([cartId]);
      },
    };
  })
  .views(self => {
    const { platform, logger } = getEnv(self);
    const {
      DeliveryStore,
      GroupOrderStore,
      MemberStore,
      MenuStore,
      OfferStore,
      OrderStore,
      OrderingContextStore,
    } = getRoot(self);

    return {
      get subCartIds() {
        return getSubCartIds(self.subCarts);
      },
      get orderItems() {
        return getSubCartOrderItems(self.subCarts);
      },
      get subCartsAsArray() {
        return getFilledSubCartsAsList(self.subCarts);
      },
      productQuantityInCart(menuProductId) {
        const productsInCartForId = filter(self.orderItems, ({ product }) => {
          const otherSizesIds = map(product.otherSizes, 'id');
          return menuProductId === product.id || includes(otherSizesIds, menuProductId);
        });
        return sumBy(productsInCartForId, 'quantity');
      },

      get orderType() {
        logger.info('Deprecated: use context store directly', 'get orderType');
        return OrderingContextStore.orderType;
      },
      set orderType(value) {
        logger.info('Deprecated: use context store directly', 'set orderType');
        OrderingContextStore.setOrderType(value);
      },

      get allOffers() {
        return OfferStore.allOffers;
      },

      get offerId() {
        return get(self, 'offer.id', null);
      },

      toPickUpTime() {
        if (self.orderTime === ORDER_TIMES.ASAP) {
          return ORDER_TIMES.ASAP; // no longer are we converting ASAP to a time in the future
        }

        // other if a user manually picks a time let's return that
        return moment(self.orderTime);
      },

      get restaurantId() {
        logger.info('Deprecated: use context store directly', 'get restaurantId');
        return OrderingContextStore.restaurantId;
      },
      get restaurant() {
        logger.info('Deprecated: use context store directly', 'get restaurant');
        return OrderingContextStore.restaurant;
      },
      set restaurant(value) {
        logger.info('Deprecated: use context store directly', 'set restaurant');

        if (typeof value === 'string') {
          OrderingContextStore.updateSettings({ restaurantId: value });
          return;
        }

        OrderingContextStore.updateSettings({ restaurantId: value?.id });
      },

      get tableNumber() {
        logger.info('Deprecated: use context store directly', 'get tableNumber');
        return OrderingContextStore.isDineIn ? OrderingContextStore.fulfilment.tableNumber : undefined;
      },
      set tableNumber(tableNumber) {
        logger.info('Deprecated: use context store directly', 'set tableNumber');
        OrderingContextStore.updateSettings({ tableNumber });
      },

      // Indicates whether the Cart UI should allow a user to checkout.
      // E.g. if no restaurant (don't allow).
      get allowCheckout() {
        if (self.loading) {
          return false;
        }

        if (MenuStore.loading) {
          return false;
        }

        const hasEmptyCart = self.orderItems.length === 0;
        if (hasEmptyCart) {
          return false;
        }

        if (!self.restaurantId) {
          return false;
        }

        // Order Type Specific.
        if (self.orderType === ORDER_TYPES.DINE_IN) {
          if (!self.tableNumber) {
            return false;
          }
        }

        if (!self.isDelivery) {
          const invalidRestaurantSelection = get(self.restaurant, 'formattedStatusMessage.disableOptions', false);
          if (invalidRestaurantSelection) {
            return false;
          }
        }

        if (self.isDelivery && !DeliveryStore.allowCheckout) {
          return false;
        }

        if (OrderStore.allErrors.length > 0) {
          return false;
        }

        return true;
      },

      get allowCreateOrder() {
        if (!self.allowCheckout) {
          return false;
        }
        if (isEmpty(self.paymentTypes)) {
          return false;
        }

        if (self.hasPaymentType(PAYMENT_TYPES.LOYALTY)) {
          const { totalPrices, offer } = self;
          const pointsBalance = MemberStore.profile.balances.points;
          const pointsRequired = totalPrices.points;
          if (pointsBalance < pointsRequired || offer) {
            return false;
          }
          if (self.pointsPaymentDisabled) {
            return false;
          }
        }

        if (self.hasPaymentType(PAYMENT_TYPES.CREDIT_CARD)) {
          if (!self.payment.paymentMethod && !self.payment.creditCard) {
            return false;
          }
        }

        if (self.hasPaymentType(PAYMENT_TYPES.GIFT_CARD)) {
          if (self.payment.giftCard === undefined) {
            return false;
          }

          if (self.payment.giftCard.hasExpired) {
            return false;
          }

          if (self.payment.giftCard.balance === 0) {
            return false;
          }

          const twoPaymentTypesSelected = size(self.paymentTypes) === 2;
          const giftCardInsufficient = self.payment.giftCard.balance * 100 < self.totalPrices.cents;
          if (giftCardInsufficient && !twoPaymentTypesSelected) {
            return false;
          }
        }

        if (self.hasPaymentType(PAYMENT_TYPES.GROUP_HOST)) {
          if (!GroupOrderStore.isGroupInvitee) {
            return false;
          }
        }

        return true;
      },

      get allowCreateGiftCardOrder() {
        if (isEmpty(self.paymentTypes) || self.loading) {
          return false;
        }

        if (self.hasPaymentType(PAYMENT_TYPES.LOYALTY, PAYMENT_TYPES.GIFT_CARD)) {
          return false;
        }

        if (self.hasPaymentType(PAYMENT_TYPES.CREDIT_CARD)) {
          if (!self.payment.paymentMethod && !self.payment.creditCard) {
            return false;
          }
        }

        return true;
      },

      get orderTime() {
        logger.info('Deprecated: use context store directly', 'get orderTime');
        if (!OrderingContextStore.isPickUp) {
          return SCHEDULE_TYPES.ASAP;
        }

        const { isAsap, time } = OrderingContextStore.fulfilment;

        if (isAsap) {
          return SCHEDULE_TYPES.ASAP;
        }

        return time;
      },

      get memberHasEnoughPointsForPurchase() {
        const { totalPrices } = self;

        if (!MemberStore.profile) {
          return false;
        }

        const pointsBalance = MemberStore.profile.balances.points;
        const pointsRequired = totalPrices.points;
        return pointsBalance >= pointsRequired;
      },

      get itemCount() {
        return self.orderItems.reduce((accumulator, orderItem) => accumulator + orderItem.quantity, 0);
      },

      get formattedItemCount() {
        const { itemCount } = self;
        if (itemCount === 1) {
          return `${itemCount} item`;
        }
        return `${itemCount} items`;
      },

      // By default, this function returns the orderItems if there is no offer or restriction.
      get eligibleOrderItems() {
        const { offer, orderItems } = self;

        // Return early if no offer.
        if (!offer) {
          return orderItems;
        }

        // Return early if no product restriction.
        const { plus = [] } = offer;
        if (isEmpty(plus)) {
          return orderItems;
        }

        // Return all orderItems containing a plu (product.plu or option.plu) in offer.plus.
        const filteredOrderItem = filter(orderItems, orderItem => {
          return includes(plus, orderItem.product.plu) || includes(plus, map(orderItem.options, 'plu'));
        });

        return filteredOrderItem;
      },

      // A Prices object indicating how much will be deducted from the subtotal.
      get discountPrices() {
        return calculateDiscountPrices({
          orderItems: self.orderItems,
          offer: self.offer,
          subtotalPrices: self.subtotalPrices,
        });
      },

      get surchargePrices() {
        const cents = sumBy(self.surcharges, 'amount');

        return { cents, points: 0 };
      },

      /**
       * Get the total of all items in the cart.
       */
      get subtotalPrices() {
        return calculateSubtotalPrices({ orderItems: self.orderItems });
      },

      /**
       * TODO: Migrate consumers to OrderStore.payableTotal and remove from CartStore
       */
      get totalPrices() {
        return OrderStore.payableTotal;
      },

      get splitAmountGiftCard() {
        if (!self.hasPaymentType(PAYMENT_TYPES.GIFT_CARD) || !self.payment.giftCard) {
          return 0;
        }

        const selectedGiftCardBalanceCents = get(self, 'payment.giftCard.balance', 0) * 100;
        return round(min([selectedGiftCardBalanceCents, self.totalPrices.cents]));
      },
      get splitAmountGiftCardFormatted() {
        return priceFormatter(self.splitAmountGiftCard);
      },
      get splitAmountNonGiftCard() {
        return self.totalPrices.cents - self.splitAmountGiftCard;
      },
      get splitAmountNonGiftCardFormatted() {
        return priceFormatter(self.splitAmountNonGiftCard);
      },

      toValidateOrderInput() {
        const paymentType = GroupOrderStore.isGroupInvitee ? PAYMENT_TYPES.GROUP_HOST : PAYMENT_TYPES.CREDIT_CARD;
        return {
          ...omit(self.toOrderInput(), ['cartId']),
          orderTotalSplitArray: [{ paymentType, orderTotal: self.totalPrices.cents }],
        };
      },

      toCalculateSurchargesInput() {
        const orderInput = omit(self.toValidateOrderInput(), ['surcharges', 'groupOrderDetails']);
        const { subtotalWithDelivery } = OrderStore;

        return {
          ...orderInput,
          orderTotal: subtotalWithDelivery.cents,
          orderTotalSplitArray: [{ paymentType: PAYMENT_TYPES.CREDIT_CARD, orderTotal: subtotalWithDelivery.cents }],
        };
      },

      get isDelivery() {
        logger.info('Deprecated: use context store directly', 'get isDelivery');
        return OrderingContextStore.isDelivery;
      },

      get isPickUp() {
        logger.info('Deprecated: use context store directly', 'get isPickUp');
        return OrderingContextStore.isPickUp;
      },

      get orderItemsToOrderInput() {
        return map(self.orderItems, orderItem => {
          const orderItemOptions = [];

          entries(orderItem.choices).forEach(([category, choiceOptions]) => {
            choiceOptions.forEach(orderOption => {
              orderItemOptions.push({
                itemId: orderOption.option.plu, // or should this be productId?
                choiceId: orderOption.option.id,
                price: orderOption.option.prices.cents,
                quantity: orderOption.quantity,
                sortOrder: orderOption.option.sortOrder,
                name: orderOption.option.name,
                category,
              });
            });
          });

          // NOTE: we need to sort all the options so they appear correctly on the printed redcat docket
          orderItemOptions.sort((a, b) => a.sortOrder - b.sortOrder);

          return {
            // notes: '', // @TODO: Fix this if we add notes per order item.
            options: orderItemOptions,
            price: orderItem.product.prices.cents, // @TODO: Determine if order item price should ever reflect a discount.
            productId: orderItem.product.id,
            quantity: orderItem.quantity,
            name: orderItem.product.name,
          };
        });
      },

      toOrderInput() {
        // Abort if menu is out of sync to avoid stale menu data
        if (!self.isInSyncWithMenu) {
          return undefined;
        }

        const deliveryParams = self.isDelivery
          ? {
              deliveryAddress: DeliveryStore.deliveryAddress,
              deliveryNotes: DeliveryStore.deliveryNotes,
              deliveryProvider: DeliveryStore.deliveryProvider,
            }
          : {};

        const surcharges = map(self.surcharges, ({ type, name, amount }) => ({
          type,
          name,
          amount,
        }));

        const groupOrderParams = GroupOrderStore.isInGroup
          ? {
              groupOrderDetails: {
                id: GroupOrderStore.id,
                inviteeName: GroupOrderStore.inviteeName,
                token: self.groupInviteeSubmissionToken,
              },
            }
          : {};

        return {
          cartId: self.cartId,
          offers: get(self.offer, 'id')
            ? [
                {
                  discountAmount: self.discountPrices.cents,
                  hash: self.offer.hash,
                },
              ]
            : [],
          orderItems: self.orderItemsToOrderInput,
          orderNotes: self.notes,
          orderTotal: self.totalPrices.cents,
          orderType: self.orderType,
          // @NOTE: orderTotalSplitArray and payment are done in toCreateOrderInput().

          // in the past we were converting all orders to scheduled orders, so this was always returning a time in the future.
          // with the new thresholds we are passing back ASAP to Redcat.
          pickUpTime:
            self.toPickUpTime() === ORDER_TIMES.ASAP ? ORDER_TIMES.ASAP : self.toPickUpTime().toISOString(true),
          receiptType: 'NONE', // @TODO: Wire up option to specify receipt.
          restaurantId: self.restaurantId,
          tableNumber: self.tableNumber,
          surcharges,
          ...groupOrderParams,
          ...deliveryParams,
          ...groupOrderParams,
        };
      },
      buildPaymentFields(adyenNativeResponse, isInviteeSubmission) {
        // These come from the Native SDK and only exist if there has been a challenge from Adyen
        const paymentDetails = get(adyenNativeResponse, 'paymentDetails');
        const paymentData = get(adyenNativeResponse, 'paymentData');
        // NOTE: Android Adyen SDK adds line breaks to the base64 data which breaks the API so remove here
        const detailsPayload = paymentDetails ? JSON.stringify(paymentDetails).replace(/\\\\n/g, '') : undefined;

        const paymentTypesWithSplitTotalsCalculated = (() => {
          if (isInviteeSubmission) {
            return [{ paymentType: PAYMENT_TYPES.GROUP_HOST, orderTotal: self.totalPrices.cents }];
          }

          return map(self.paymentTypes, paymentType => ({
            paymentType,
            orderTotal:
              paymentType === PAYMENT_TYPES.GIFT_CARD
                ? self.splitAmountGiftCard
                : self.totalPrices.cents - self.splitAmountGiftCard,
          }));
        })();

        return {
          orderTotalSplitArray: paymentTypesWithSplitTotalsCalculated,
          paymentDetails: detailsPayload,
          paymentData,
          payment: self.hasPaymentType(PAYMENT_TYPES.LOYALTY)
            ? null
            : {
                // @TODO: Fix this so that it's more obvious / automated rather than messing with objects.
                encryptedCardNumber: get(self, 'payment.creditCard.encryptedCardNumber'),
                encryptedExpiryMonth: get(self, 'payment.creditCard.encryptedExpiryMonth'),
                encryptedExpiryYear: get(self, 'payment.creditCard.encryptedExpiryYear'),
                encryptedSecurityCode: get(self, 'payment.creditCard.encryptedSecurityCode'),
                holderName: get(self, 'payment.creditCard.name'),
                recurringDetailReference: self.payment.useNewCreditCard
                  ? null
                  : get(self, 'payment.paymentMethod.id', null),
                storePaymentMethod: self.payment.storePaymentMethod,
              },
        };
      },
      toCreateOrderInput(adyenNativeResponse = {}) {
        const guestCheckoutData = self.guestCheckoutCustomerDetails
          ? { guestCheckoutCustomerDetails: self.guestCheckoutCustomerDetails }
          : {};

        return {
          ...self.toOrderInput(),
          alternativePaymentMethod: get(self, 'payment.alternativePaymentMethod'),
          browserInfo: get(self, 'payment.browserInfo'),
          channel: platform,
          ...self.buildPaymentFields(adyenNativeResponse, GroupOrderStore.isGroupInvitee),
          riskData: get(self, 'payment.creditCard.riskData'),
          shopperDetails: MemberStore.shopperDetails,
          giftCard: self.hasPaymentType(PAYMENT_TYPES.GIFT_CARD)
            ? {
                cardNumber: self.payment.giftCard.cardNumber,
                verificationCode: self.payment.giftCard.verificationCode,
              }
            : null,
          ...guestCheckoutData,
        };
      },
      hasPaymentType(...paymentTypes) {
        return some(intersection(self.paymentTypes, paymentTypes));
      },
      get isGuestOrderingAvailable() {
        let { restaurant } = self;
        if (self.isDelivery) {
          restaurant = DeliveryStore.deliveryRestaurant;
        }

        if (self.isDineIn) {
          return get(restaurant, 'dineInGuestCheckoutEnabled', false);
        }
        if (self.isDelivery) {
          return get(restaurant, 'deliveryGuestCheckoutEnabled', false);
        }
        if (self.isPickUp) {
          return get(restaurant, 'pickUpGuestCheckoutEnabled', false);
        }
        return false;
      },
      /**
       * TODO: Migrate consumers to OrderStore.formattedDeliveryCost and remove from CartStore.
       */
      get formattedDeliveryCost() {
        return OrderStore.formattedDeliveryCost;
      },
      get formattedOrderType() {
        return {
          [ORDER_TYPES.DELIVERY]: 'delivery',
          [ORDER_TYPES.PICK_UP]: 'pick up',
          [ORDER_TYPES.DINE_IN]: 'dine in',
        }[self.orderType];
      },
      get pointsPaymentDisabled() {
        return (
          self.pointsPaymentDisabledDueToOffer ||
          self.pointsPaymentDisabledDueToDeliveryOffer ||
          self.hasHolidaySurcharge
        );
      },
      get pointsPaymentDisabledDueToOffer() {
        return get(self, 'offer.id') !== undefined;
      },
      get pointsPaymentDisabledDueToDeliveryOffer() {
        if (self.isDelivery && DeliveryStore.deliveryCost !== null) {
          return DeliveryStore.deliveryCost.cents === 0;
        }
        return false;
      },
      get pointsPaymentDisabledText() {
        if (self.pointsPaymentDisabledDueToOffer) {
          return 'Unavailable with offer.';
        }
        if (self.pointsPaymentDisabledDueToDeliveryOffer) {
          return DeliveryStore.deliveryCost.cents === 0 ? 'Unavailable with Free Delivery offer.' : null;
        }
        if (self.hasHolidaySurcharge) {
          return 'Points payments can’t be used when a Public Holiday surcharge is added.';
        }
        return null;
      },

      get isDineIn() {
        logger.info('Deprecated: use context store directly', 'get isDineIn');
        return OrderingContextStore.isDineIn;
      },

      get isInSyncWithMenu() {
        return self.menuVersion === get(MenuStore, 'menu.version');
      },

      get productsInCart() {
        return map(self.orderItems, ({ product }) => product);
      },

      // @move: Belongs to PickUpFulfilment
      get formattedEstimate() {
        return moment().add(self.orderEstimateMinutes, 'm');
      },

      // @move: Belongs to PickUpFulfilment (pass in subTotal)
      get orderEstimateMinutes() {
        const { defaultOrderEstimate, orderType, restaurant } = self;
        const { subTotal } = OrderStore;

        if (!restaurant) {
          return defaultOrderEstimate;
        }

        if (subTotal.cents === 0) {
          return restaurant.averageOrderTime;
        }

        const thresholds = filter(
          restaurant.thresholds,
          threshold => threshold.lowerThresholdAmount < subTotal.cents && threshold.orderType === orderType
        );

        return reduce(thresholds, (sum, threshold) => sum + threshold.time, restaurant.averageOrderTime);
      },

      get orderEstimateFormatted() {
        if (self.orderEstimateMinutes) {
          const { formattedEstimate } = self;

          return `Ready in ${formattedEstimate.fromNow(true)}`;
        }

        return undefined;
      },

      // @move: Belongs to PickUpFulfilment but can't move until `orderEstimateMinutes` moves
      get estimatedPickUpTime() {
        if (!OrderingContextStore.isPickUp) {
          return undefined;
        }

        if (OrderingContextStore.fulfilment?.isAsap) {
          return self.orderEstimateFormatted;
        }

        return OrderingContextStore.fulfilment?.formattedOrderTime;
      },

      get guestCheckoutCustomerDetailsAsInitialValues() {
        const snapshot = self.guestCheckoutCustomerDetails ? getSnapshot(self.guestCheckoutCustomerDetails) : {};
        return {
          givenName: snapshot.givenName || '',
          surname: snapshot.surname || '',
          mobile: snapshot.mobile || '',
          email: snapshot.email || '',
        };
      },

      get isEmpty() {
        return self.orderItems.length === 0;
      },

      get holidaySurcharge() {
        return find(self.surcharges, surcharge => surcharge.type === SURCHARGE_TYPE.HOLIDAY_SURCHARGE);
      },

      get hasHolidaySurcharge() {
        return !!self.holidaySurcharge;
      },

      get hasReordered() {
        return self.orderItems.some(({ isReorderItem }) => isReorderItem);
      },

      get anythingToUndo() {
        return self.removedSubCarts.size > 0;
      },

      get isCartRemoved() {
        return (
          self.anythingToUndo &&
          self.subCarts.size === 1 &&
          self.subCarts.get(DEFAULT_SUB_CART_ID).orderItems.length === 0
        );
      },

      get removedSubCartIds() {
        return [...self.removedSubCarts.entries()].map(([id, { name }]) => ({ id, name }));
      },
      get knownSubCartIds() {
        return [...self.knownSubcartIdMap.keys()];
      },
    };
  });

CartStore.initialState = initialState;

export default CartStore;
