import { flow, getEnv, types } from 'mobx-state-tree';
import get from 'lodash/get';
import gql from 'graphql-tag';
import moment from 'moment';

const initialState = {};

const ADD_RECEIPT_MUTATION = gql`
  mutation addReceipt($input: AddReceiptInput!) {
    addReceipt(input: $input)
  }
`;
const ADD_RECEIPT_MEMBER_MUTATION = gql`
  mutation addReceiptMember($input: AddReceiptInput!) {
    addReceiptMember(input: $input)
  }
`;

const addReceipt = ({ client, input }) =>
  client.mutate({
    mutation: ADD_RECEIPT_MUTATION,
    variables: { input },
  });

const addReceiptMember = ({ client, input }) =>
  client.mutate({
    mutation: ADD_RECEIPT_MEMBER_MUTATION,
    variables: { input },
  });

const ReceiptStore = types.model('ReceiptStore', {}).actions(self => {
  const { getApiClient, logger } = getEnv(self);
  return {
    addReceipt: flow(function*({ restaurantId, receiptNumber, date, time, total }) {
      try {
        const input = {
          restaurantId,
          receiptNumber,
          transactionDateTime: `${moment(date).format('YYYY-MM-DD')}T${moment(time).format('HH:mm:ss')}`,
          total,
        };
        const client = yield getApiClient();
        yield addReceipt({ client, input });
      } catch (error) {
        const message = get(error, 'errors[0].message', error.message);
        logger.error('There was an error adding receipt', { message });
        throw Error(message);
      }
    }),
    addReceiptMember: flow(function*({ restaurantId, receiptNumber, date, time, total, tokenReCaptchaV2 }) {
      try {
        const input = {
          restaurantId,
          receiptNumber,
          transactionDateTime: `${moment(date).format('YYYY-MM-DD')}T${moment(time).format('HH:mm:ss')}`,
          total,
          tokenReCaptchaV2,
        };
        const client = yield getApiClient();
        yield addReceiptMember({ client, input });
      } catch (error) {
        const message = get(error, 'errors[0].message', error.message);
        logger.error('There was an error adding receipt', { message });
        throw Error(message);
      }
    }),
  };
});

ReceiptStore.initialState = initialState;

export default ReceiptStore;
