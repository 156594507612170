import { types } from 'mobx-state-tree';

const GuestCheckoutCustomerDetails = types.model('GuestCheckoutCustomerDetails', {
  givenName: types.string,
  surname: types.string,
  mobile: types.string,
  email: types.string,
  token: types.maybe(types.string),
});

export default GuestCheckoutCustomerDetails;
