import { types } from 'mobx-state-tree';

const StoredPaymentMethod = types
  .model('StoredPaymentMethod', {
    id: types.identifier,
    expiryMonth: types.string,
    expiryYear: types.string,
    brand: types.string,
    lastFour: types.string,
    name: types.string,
  })
  .views(self => ({
    get formattedCard() {
      return `Ends in: ${self.lastFour} Expires: ${self.expiryMonth}/${self.expiryYear}`;
    },
    get browserInfo() {
      // Adapted from: https://github.com/Adyen/adyen-3ds2-js-utils/blob/main/browser/index.js#L12
      const defaults = {
        acceptHeader: '*/*',
      };

      if (typeof window === 'undefined') {
        return defaults;
      }

      const { navigator } = window;

      return {
        ...defaults,
        screenWidth: window.screen?.width ?? '',
        screenHeight: window.screen?.height ?? '',
        colorDepth: window.screen?.colorDepth ?? '',
        userAgent: window.screen?.userAgent ?? '',
        timeZoneOffset: new Date().getTimezoneOffset(),
        language: navigator?.language ?? navigator.browserLanguage,
        javaEnabled: false,
      };
    },
  }));

export default StoredPaymentMethod;
