import { applySnapshot, flow, getEnv, getSnapshot, types } from 'mobx-state-tree';
import { find, intersection, map, uniqWith } from 'lodash';
import moment from 'moment';

import { findUniqueNonNullGroupNames, groupMenuItemsByGroupName } from '../../util/group-menu-items-by-group-name';
import { getProduct } from '../../helpers/get-product';
import MenuPromotion from '../menu-promotion';
import Product from '../product';
import ProductCategory from '../product-category';

const removeFirstEntry = (array, valueToRemove) => {
  const index = array.indexOf(valueToRemove);
  if (index > -1) array.splice(index, 1);
  return array;
};

const removeAllFirstEntries = (array, valuesToRemove) => {
  return valuesToRemove.reduce((acc, valueToRemove) => removeFirstEntry(acc, valueToRemove), array);
};

const Menu = types
  .model('Menu', {
    categories: types.array(ProductCategory),
    id: types.identifier,
    nutrition: types.frozen(),
    products: types.array(Product),
    recommendedProducts: types.optional(types.array(types.safeReference(Product)), []),
    featuredProducts: types.optional(types.array(types.safeReference(Product)), []),
    lastUpdated: types.optional(types.Date, () => new Date()),
    menuPromotion: types.maybe(types.maybeNull(MenuPromotion)),
    showStockShortageAlert: types.optional(types.boolean, false),
  })
  .actions(self => {
    const { getApiClient } = getEnv(self);

    const updateProduct = (id, attributes) => {
      const product = find(self.products, { id });

      if (product) {
        product.update(attributes);
      } else {
        self.products.push(attributes);
      }
    };

    return {
      update(attributes) {
        applySnapshot(self, attributes);
        self.lastUpdated = new Date();
      },

      /**
       * Load the details of a given product into the menu.
       * Typically used for lazy-loading products.
       *
       * @param {string} productId Id of the product to load
       */
      loadProduct: flow(function*(productId, groupProductSizes = true) {
        const client = yield getApiClient();
        const result = yield getProduct({
          client,
          orderType: self.orderType,
          productId,
          restaurantId: self.restaurantId,
        });
        const { data, error } = result;

        if (error) {
          throw error;
        }

        updateProduct(productId, data);
        if (groupProductSizes) {
          self.groupProductSizes();
        }
      }),

      /**
       * Load the details of a given set of products into the memory.
       * Typically used for lazy-loading products.
       *
       * @param {string[]} productIds Ids of the products to load
       */
      loadProducts: flow(function*(productIds) {
        yield Promise.all(productIds.map(productId => self.loadProduct(productId, false)));
        self.groupProductSizes();
      }),

      groupProductSizes() {
        self.products = groupMenuItemsByGroupName({ items: self.products, otherSizesAsIds: true });
        self.categories = map(self.categories, category => ({
          ...category,
          products: uniqWith(category.products, findUniqueNonNullGroupNames),
        }));
      },
    };
  })
  .views(self => ({
    get version() {
      return `${self.id}:${moment(self.lastUpdated).toISOString()}`;
    },

    /**
     * Due to a bug in mobxUtils.now() we can't use a getter here as once a ticker goes
     * inactive due to lack of subscribers, it's latest value will be returned initially
     * when resubscribed.
     *
     * Once this fix is released we can switch to a getter:
     * https://github.com/mobxjs/mobx-utils/pull/272
     */
    isStale() {
      return moment(Date.now()).diff(self.lastUpdated, 'minutes') >= 15;

      // Replace with this once mobx-utils is updated
      // return moment(mobxUtils.now()).diff(self.lastUpdated, 'minutes') >= 15;
    },

    /**
     * Searches through the menu for the Product (by id)
     * Returns the first matching instance of Product model when found
     * Returns undefined if no matching product could be found
     * @param {Product.identifier} id
     */
    getProductById(id) {
      return find(self.products, { id });
    },

    /**
     * Searches through the menu for the Product (by PLU)
     * Returns the first matching instance of Product model when found
     * Returns undefined if no matching product could be found
     * @param {Product.plu} plu
     */
    getProductByPlu(plu) {
      return find(self.products, { plu });
    },

    getProductNutrition(PLUs = []) {
      if (!self.nutrition) {
        return [];
      }

      // PLUs is an array of all product + option PLUs in the current order item
      // We need to find the menu's nutrition data based on the key which best satisfies the combination of PLUs provided.
      const nutritionKeys = Object.keys(self.nutrition || {});

      let remainingPLUsToFind = PLUs;
      const findNutritionItems = () =>
        nutritionKeys.reduce((acc, key) => {
          const item = self.nutrition[key];

          const individualPlus = key.split(':');
          const shared = intersection(individualPlus, PLUs);

          if (shared.length === individualPlus.length) {
            // remove found PLU's from the list of PLUs to find
            remainingPLUsToFind = removeAllFirstEntries(remainingPLUsToFind, shared);
            if (item.showNutrition) {
              acc.push({
                ...item,
                nutrition: {
                  ...item.nutrition,
                  isMainProductPluWithChoices: key.split(':').length > 1,
                  getIsMainProductPluWithoutChoices: plu => key === plu,
                },
              });
            }
          }

          return acc;
        }, []);

      // Keep finding nutrition items until all PLUs have been found or none of the remaining PLUs exist in nutritionKeys. This is to handle the case where an orderItem has multiple of the same PLU (e.g. 2x Chips)
      let items = [];
      let nutritionItems = [];
      do {
        nutritionItems = findNutritionItems();
        items = items.concat(nutritionItems);
      } while (nutritionItems.length > 0);

      return items;
    },

    /**
     * Searches through the menu for the Category containing Product (by id)
     * Returns the first matching instance of Category model when found
     * Returns undefined if no matching product could be found
     * @param {Product.identifier} id
     */
    getProductCategory(id) {
      const productCategory = find(self.categories, ({ products }) => Boolean(find(products, { id })));
      return productCategory;
    },

    /**
     * Determine whether a given product has been loaded into the menu yet.
     *
     * @param {string} productId
     */
    productLoaded(productId) {
      return self.getProductById(productId) !== undefined;
    },

    /**
     * Determine whether a given product exists in the menu, either as a main menu item or as an alternative size.
     *
     * @param {string} productId
     */
    hasProduct(productId) {
      return self.products.some(
        product => product.id === productId || getSnapshot(product.otherSizes).includes(productId)
      );
    },

    get restaurantId() {
      const idParts = self.id.split('-');

      if (idParts.length === 1) {
        return idParts[0];
      }

      return idParts[1];
    },

    get orderType() {
      const idParts = self.id.split('-');

      if (idParts.length === 1) {
        return undefined;
      }

      return idParts[0];
    },
  }));

export default Menu;
